import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { AccountServiceProvider} from '../providers/account-service/account-service';

@Injectable({
	providedIn: 'root'
})

export class FuncsService {
	public closedTime       : any;
	public currentTime      : any;
	public action           : any = 'listing';
	public isMobile         : boolean;

	constructor(
		private router         : Router,
		private titleService   : Title,
  		private meta           : Meta,
		private accountService : AccountServiceProvider,
	) {
		this.isMobile = this.globalDetectDevice();
	}

	globalDetectDevice() {
		if ( devicePixelRatio > 1.5 && document.body.clientWidth < 767 )
            return true;
        else
            return false;
	}

	globalSetUserSession() {
		if ( 'userData' in window.localStorage )
			return JSON.parse( window.localStorage.getItem( 'userData' ) );
	}

	globalNavigate( url ) {
		this.router.navigate( [ url ] );
	}

	globalSeoData( seoData, alternateTitle = null ) {
		if ( null != alternateTitle )
			this.titleService.setTitle( '' != seoData.seo_data.title && null != seoData.seo_data.title ? seoData.seo_data.title : alternateTitle );
		else
			this.titleService.setTitle( seoData.seo_data.title );
		this.meta.updateTag({
							name: 'keywords',
							content: seoData.seo_data.focuskw,
						});
		this.meta.updateTag({
							name: 'description',
							content: seoData.seo_data.metadesc,
						});
	}

	globalLocalStorageHttp() {
		window.localStorage.setItem( 'httpReferer', this.router.url );
	}

	globalnonCurrentUserMCIMessage() {
		setTimeout( () => {
			const biscrollElement = document.getElementById( 'authenticate-mci-message' );
			const biyCoordinate = biscrollElement.getBoundingClientRect().top + window.pageYOffset;
			let biyOffset;

			if( this.isMobile ) {
				biyOffset = -60;
			} else {
				biyOffset = 0;
			}

			window.scrollTo({
				top: biyCoordinate + biyOffset,
				behavior: 'smooth'
			});
	 	}, 300 );
	}

	globalnonLoggedinUserMessage() {
		setTimeout( () => {
			const biscrollElement = document.getElementById( 'authenticate-message' );
			const biyCoordinate = biscrollElement.getBoundingClientRect().top + window.pageYOffset;
			let biyOffset;

			if( this.isMobile ) {
				biyOffset = -60;
			} else {
				biyOffset = 0;
			}

			window.scrollTo({
				top: biyCoordinate + biyOffset,
				behavior: 'smooth'
			});
	 	}, 300 );
	}

	globalnonAuthenticatedCommunityMessage() {
		setTimeout( () => {
			const biscrollElement = document.getElementById( 'authenticate-message-community' );
			const biyCoordinate = biscrollElement.getBoundingClientRect().top + window.pageYOffset;
			let biyOffset;

			if( this.isMobile ) {
				biyOffset = -60;
			} else {
				biyOffset = 0;
			}

			window.scrollTo({
				top: biyCoordinate + biyOffset,
				behavior: 'smooth'
			});
		}, 300 );
	}

	/*globalsaveFavourite( userId, favouriteId, params, data, objType ) {
		this.accountService.saveFavourite( params ).then( ( dotnetresponse: any ) => {
			if ( dotnetresponse.status == 'Ok' ) {

				let objIndex: any;
				if ( 'participantid' == objType )
					objIndex = data.findIndex( ( obj => obj.participantid == userId ) );
				else if ( 'userId' == objType )
					objIndex = data.findIndex( ( obj => obj.userId == userId ) );
				else
					objIndex = data.findIndex( ( obj => obj.id == userId ) );
				//Update object's name property.
				if ( 0 == favouriteId ) {				
					data[ objIndex ].favouriteId = dotnetresponse.id;
				} else {					
					data[ objIndex ].favouriteId = 0;
				}
			}
		});
	}*/

	globalHighlightSearchText( content, type = null, position = null ) {
		if ( '' != content || null != content || 'null' != content || 'undefined' !=  typeof content || 'undefined' != content ) {
			const search_text = (<HTMLInputElement>document.getElementById( 'hidden-search-text' ) ).value;
			var lower_search_text = search_text.toLowerCase();
			var upper_search_text = search_text.toUpperCase();
			var upper_first_search_text = search_text.charAt(0).toUpperCase() + search_text.slice(1);
			if ( search_text.includes( ' ' ) ) {
				var upper_every_first_search_text = this.titleCase( search_text );
			}
			let changed_content = content;

			if ( changed_content.indexOf( lower_search_text ) != -1 ) {
				changed_content = changed_content.replace( lower_search_text, '<span class="search__text__highlight">' + lower_search_text + '</span>' );
			}

			if ( changed_content.indexOf( upper_search_text ) != -1 ) {
				changed_content = changed_content.replace( upper_search_text, '<span class="search__text__highlight">' + upper_search_text + '</span>' );
			}

			if ( changed_content.indexOf( upper_first_search_text ) != -1 ) {
				changed_content = changed_content.replace( upper_first_search_text, '<span class="search__text__highlight">' + upper_first_search_text + '</span>' );
			}

			if ( search_text.includes( ' ' ) ) {
				if ( changed_content.indexOf( upper_every_first_search_text ) != -1 ) {
					changed_content = changed_content.replace( upper_every_first_search_text, '<span class="search__text__highlight">' + upper_every_first_search_text + '</span>' );
				}
			}

			if ( null !== type ) {
				if ( false == position ) {
					changed_content += '<b> | </b>';
				}
			}
			
			return changed_content;
		}
	}

	titleCase( str ) {
		var splitStr = str.toLowerCase().split(' ');
		for (var i = 0; i < splitStr.length; i++) {
			splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
		}
		return splitStr.join(' '); 
	}
}