<div class="login-signup-wrapper">
	<p *ngIf="is_page_with_login" class="popup-login-msg">You need to login to access this page</p>
	<button type="button" class="close" aria-label="Close" (click)="closeModal();">
		<span aria-hidden="true">
			<span class="line-left"></span>
			<span class="line-right"></span>
		</span>
	</button>	
	<div class="content-wrapper">
		<div class="login-signup-login form-wrapper">
			<div class="login-signup__heading">Convenor Sign in</div>
			<div class="login-signup__headingsmall text-left">Have an existing convenor account? Sign in here: </div>
			<div class="login-signup__form-wrapper">
				<form [formGroup]="loginForm">
					<div class="form-group">
						<div class="floating-label" [class.input-filled]="emailFilled == true">
							<input type="email" formControlName="email" id="email" class="form-control" autocomplete="off"/>
							<label><span>E-mail</span></label>
						</div>
						<bfv-messages></bfv-messages>
					</div>
					<div class="form-group">
						<div class="floating-label" [class.input-filled]="passwordFilled == true">
							<input type="password" id="password" formControlName="password" class="form-control" autocomplete="off"/>
							<label><span>Password</span></label>
						</div>
						<bfv-messages></bfv-messages>
					</div>
					<div class="form-group button-wrapper">
						<button type="submit" class="link-span btn btn-bluelight btn-circle" (click)="doLogin()" *ngIf="!loginLoad">
							<span>Sign in</span> <i class="icon-arrow-right"></i>
						</button>
						<button class="link-span btn btn-bluelight btn-circle" *ngIf="loginLoad">
							<span>Logging in ...</span>
						</button>
						<div class="message-wrapper">
							<span class="error" *ngIf="loginErrorMessage == true && '' != loginerrorMessageText" [innerHtml]="loginerrorMessageText"></span>
							<span class="error" *ngIf="loginErrorMessage == true && '' == loginerrorMessageText">if you are having problems logging in please use this <a href="{{serverPath}}account/login">link</a> instead.If the problem continues, please contact: <a href="mailto:worldwaterweek@siwi.org">worldwaterweek@siwi.org</a></span>
						</div>
						<div class="form-group text-center">
							<p><a href="javascript:void(0);" (click)="forgotPassword();">Forgot your password?</a></p>
						</div>
					</div>
				</form>				
			</div>
		</div>
		<div class="login-signup-signup form-wrapper">
			<div class="login-signup__heading">Create account</div>
			<div class="login-signup__headingsmall text-left">First time applying to host a session and/or expo at World Water Week? Create your convenor sign in! <br/>For abstract submissions please sign in or create an account.</div>
			<div class="login-signup__form-wrapper">
				<form [formGroup]="signupForm">
					<div class="form-group">
						<div class="floating-label" [class.input-filled]="FirstNameFilled == true">
							<input type="text" formControlName="FirstNameField" id="FirstNameField" class="form-control" autocomplete="off"/>
							<label><span>First Name</span></label>
						</div>
						<bfv-messages></bfv-messages>
					</div>
					<div class="form-group">
						<div class="floating-label" [class.input-filled]="LastNameFilled == true">
							<input type="text" formControlName="LastNameField" id="LastNameField" class="form-control" autocomplete="off"/>
							<label><span>Last Name</span></label>
						</div>
						<bfv-messages></bfv-messages>
					</div>
					<div class="form-group">
						<div class="floating-label" [class.input-filled]="EmailAddressFilled == true">
							<input type="email" formControlName="EmailField" id="EmailField" class="form-control" autocomplete="off"/>
							<label><span>E-mail Address</span></label>
						</div>
						<bfv-messages></bfv-messages>
					</div>
					<div class="form-group">
						<ngx-select [items]="countryData" (select)="handleChange( $event )" [placeholder]="'Select Country'"></ngx-select>
					</div>
					<div class="form-group button-wrapper">
						<button type="submit" class="link-span btn btn-bluelight btn-circle" (click)="doSignUp()" *ngIf="!signupLoad">
							<span>Create Account</span>
							<i class="icon-arrow-right"></i>
						</button>
						<button class="link-span btn btn-bluelight btn-circle" *ngIf="signupLoad">
							<span>Loading ...</span>
						</button>
					</div>
				</form>
				<div class="message-wrapper">
					<span class="error" *ngIf="errorMessage == true && '' != errorMessageText" id="signuperror" [innerHtml]="errorMessageText"></span>
					<span class="error" *ngIf="errorMessage == true && '' == errorMessageText" id="signuperror">if you are having problems signing up please use this <a href="{{serverPath}}account/login">link</a> instead.If the problem continues, please contact: <a href="mailto:worldwaterweek@siwi.org">worldwaterweek@siwi.org</a></span>
				</div>
				<div class="message-wrapper">
					<span class="success" *ngIf="successMessage == true" id="signupsuccess" [innerHtml]="successMessageText"></span>
				</div>
			</div>
		</div>
	</div>
</div>