import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { CountUp } from 'countup.js';

@Component({
	selector      : 'app-info',
	templateUrl   : './info.component.html',
	encapsulation : ViewEncapsulation.None
})

export class InfoComponent implements OnInit {
	@Input() infoData : any;
	public countNum = 1;
	public counter : boolean = true;	

	constructor() {}

	ngOnInit() {}

	startCounter( counter ) {
		if ( true === counter ) {
			if ( typeof this.infoData != 'undefined' ) {
				let options;
				for( let i = 0; i < this.infoData.project_information.length; i++ ) {
					if( this.infoData.project_information[i].total > 0 && this.infoData.project_information[i].total < 75 )
						options = {
							duration: this.infoData.project_information[i].total/100,
							  separator: ' ',
						};
					else if( this.infoData.project_information[i].total > 75 && this.infoData.project_information[i].total < 150 )
						options = {
							duration: this.infoData.project_information[i].total/150,
							  separator: ' ',
						};
					else if( this.infoData.project_information[i].total > 150 && this.infoData.project_information[i].total < 300 )
						options = {
							duration: this.infoData.project_information[i].total/200,
							  separator: ' ',
						};
					else if( this.infoData.project_information[i].total > 300 && this.infoData.project_information[i].total < 500 )
						options = {
							duration: this.infoData.project_information[i].total/300,
							  separator: ' ',
						};
					else
						options = {
							duration: this.infoData.project_information[i].total/2000,
							  separator: ' ',
						};

					let countUp = new CountUp( 'counter-' + i, this.infoData.project_information[i].total, options );
					countUp.start();
				}
				this.countNum++;
				if ( 3 === this.countNum )
					this.counter = false;
			}
		}
	}
}