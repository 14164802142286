import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AccountServiceProvider } from '../../providers/account-service/account-service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	encapsulation: ViewEncapsulation.None
})

export class FooterComponent implements OnInit {
	@Input() copyrightInfo: any;
	@Input() currentYear: any;
	@Input() topFooterCols: any;
	@Input() bottomFooterFirstBlockLink: any;
	@Input() bottomFooterFirstCols: any;
	@Input() bottomFooterSecondBlockLink: any;
	@Input() bottomFooterSecondCols: any;
	@Input() bottomFooterContact: any;
	public apiStatus: any;
	public prev_years: any;

	constructor(
		private accountService: AccountServiceProvider,
		private router: Router,
	) {
		this.accountService.getMenusFooter().then((result: any) => {
			if ('Ok' == result.status) {
				this.apiStatus = result.status;
				this.copyrightInfo = result.copyright;
				this.currentYear = new Date().getFullYear();
				this.topFooterCols = result.top_footer_content.four_column_menu_repeater;
				this.bottomFooterFirstBlockLink = result.bottom_footer_content.first_column_block_link;
				this.bottomFooterFirstCols = result.bottom_footer_content.first_column_links;
				this.bottomFooterSecondBlockLink = result.bottom_footer_content.second_column_block_link;
				this.bottomFooterSecondCols = result.bottom_footer_content.second_column_links;
				this.bottomFooterContact = result.contact;
			}
		});
	}

	ngOnInit() {}

	getPrevYears() {
		this.accountService.getPrevYears().then((dotnetresponse: any) => {
			if ('Ok' == dotnetresponse.status)
				this.prev_years = dotnetresponse.years;
		});
	}

	archiveUrl(url) {
		this.router.navigate(["programme/schedule/" + url.id + "-" + url.year]);
		setTimeout(() => {
			window.location.reload();
		}, 5);
	}
}