<div class="newsletter__wrapper" >
    <section id="newsletter" class="component component--newsletter bg-color-skyblue text-color-dark">
        <div class="wrapper">
            <div class="row">
                <div class="col-sm-12">
                    <div class="home-newsletter-box">
                        <div class="home-newsletter-content">
                            <div class="above-heading" *ngIf="'' != newsletterData.form_details.title" [innerHtml]="newsletterData.form_details.title"></div>
                            <div class="home-nesletter-heading" *ngIf="'' != newsletterData.form_details.sub_title" [innerHtml]="newsletterData.form_details.sub_title"></div>
                            <p *ngIf="'' != newsletterData.form_details.text" [innerHtml]="newsletterData.form_details.text"></p>
                        </div>
                        <div class="home-newsletter-form">
                            <div class="newsletter_notice" *ngIf="'' != newsletterData.form_details.notice" [innerHtml]="newsletterData.form_details.notice"></div>
                            <form [formGroup]="formGroup" action="{{ newsletterData.form_details.posturl }}" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate (validSubmit)="submit()">
                                <input type="hidden" name="id" value="{{ newsletterData.form_details.id }}" class='fid'>
                                <div id="mc_embed_signup_scroll">
                                    <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
                                    <div class="mc-field-group-grid">
                                        <div class="mc-field-group">
                                            <input type="text" value="" name="{{ newsletterData.form_details.fname }}" class="fname" id="mce-{{ newsletterData.form_details.fname }}" placeholder="Your Name" formControlName="Name">
                                        </div>
                                        <input type="hidden" value="-" name="{{ newsletterData.form_details.lname }}" class="lname" id="mce-{{ newsletterData.form_details.lname }}" placeholder="Your Name"  >						 
                                        <div class="form-group mc-field-group" [class.input-filled]="Emailfilled == true">
                                            <input type="email" value="" name="{{ newsletterData.form_details.email }}" class="form-control email" id="mce-{{ newsletterData.form_details.email }}" placeholder="Email Address *" class="email" formControlName="EMAIL" >
                                            <bfv-messages></bfv-messages>
                                        </div>			
                                        <div class="mc-field-group mc-field-fullwidth">
                                            <input type="checkbox" value="{{ newsletterData.form_details.agreev }}" name="{{newsletterData.form_details.agree}}" class="required fancy-check" id="mce-{{newsletterData.form_details.agree}}" formControlName="acceptTerms"  >
                                            <label for="mce-{{newsletterData.form_details.agree}}" class="fancy-check-label">
                                                <span class="fancy-label--text">{{ newsletterData.form_details.agreev }} *</span>
                                                <span class="fancy-checkbox">
                                                    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                        <path class="checkmark-path" fill="none" d="M4 10 l5 4 8-8.5"/>
                                                    </svg>
                                                </span> 
                                                <div style="width: 100%;
                                                margin-top: 0.25rem;
                                                font-size: 80%;
                                                color: #de3831;" *ngIf="Checkboxfilled == true"><span>This field is required</span></div>								
                                            </label>
                                        </div>
                                    </div>
                                    <div id="mce-responses" class="clear">
                                        <div class="error" id="mce-error-response" *ngIf="true == error_show" [innerHtml]="error_msg"></div>
                                        <div class="success" id="mce-success-response" *ngIf="true == success_show" [innerHtml]="success_msg"></div>
                                    </div> <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                                    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="{{ newsletterData.form_details.key }}newsletter data" tabindex="-1" value="" class="keycode"></div>
                                    <div class="clear buttonwrapper">
                                        <button type="submit" name="subscribe" id="mc-embedded-subscribe" class="btn btn-primary-light btn-witharrow" [disabled]="sendButton"><span class="btn-label">Sign up</span></button>
                                    </div>
                                </div>
                                <div class="col-12 text-center" *ngIf="sendButton == true">
                                    <span><span class="loading__circle loading__circle-small"></span> Sending</span>
                                </div>
                            </form>                            
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </section>
</div>