import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import CryptoJS from 'crypto-js';
import { Guid } from "guid-typescript";
import { Config } from '../app/config';

@Injectable()
export class Webservice {
	private webserviceUrl : string;
    private nonce         : any;
    private rand_time     : number;

	constructor(
        public http: HttpClient,
        private cfg: Config
    ) {
        this.webserviceUrl = this.cfg._apiSiteWordpress || 'https://dev.websearchpro.net/siwiv2/wp-json/siwiWP/v1/';
        this.rand_time = Math.floor(100000 + Math.random() * 900000);
    }

    public getHeaderFooterMenu( params ) {
        return this.webCallGet( '', 'header_footer_menu' + '/' + params.current_option_id );
    }

    public getMenusFooter() {
        return this.webCallGet( '', 'footer_menus' );
    }

    public getNewHeader() {
        return this.webCallGet( '', 'new_header_menu' );
    }

    public getSearchDropDownData( params ) {
        return this.webCallGet( '', 'search_drop_down_menu' + '/' + params.current_option_id );
    }

    public getStartPageData() {
        return this.webCallGet( '', 'start_page_data' );
    }

    public getInnerPageData( params ) {
        let slug = params.slug.replace( new RegExp('/', 'g'), ',' );
        slug = slug.replace( ',', '/' );
        // slug = slug.replace(/.$/,"");
        let id = params.id;
        if ( null == params.id || '' == params.id )
            id = 0;
        if ( slug.includes( '/community,organizations?tg=' ) )
            return this.webCallGet( '', 'page_data/community,organizations' + '/' + id );
        else
            return this.webCallGet( '', 'page_data' + slug + '/' + id );
    }

    public getProgrammePageData( params ) {
        let slug = params.slug.replace( new RegExp('/', 'g'), ',' );
        slug = slug.replace( ',', '/' );
        // slug = slug.replace(/.$/,"");
        let id = JSON.parse( sessionStorage.getItem( 'currentPageID' ) );
        if ( null == id || '' == id )
            id = 0;
        return this.webCallGet( '', 'programme_page_data' + slug + '/' + id );
    }

    public getMoreNews( params ) {
        return this.webCallGetMoreData( params, 'more_data' );
    }

    public getSearchData( params ) {
        return this.webCallSearchData( params, 'search_data' );
    }

    public getSearchOptions( params ) {
        return this.webCallGet( '', 'search_options' + '/' + params.current_option_id );
    }

    public getBreadCrumb( params ) {
        return this.webCallInnerPageGet( params, 'breadcrumb_data' );
    }

    public getKeynote( params ) {
        return this.webCallGet( '', 'keynote_date' + '/' + params.limit );
    }

    public getCommunityTabContent( params ) {
        return this.webCallGet( '', 'community_content_tab' + params.slug );
    }

    public getNewsDetail( params ) {
        return this.webCallGet( '', 'news_detail/' + params.slug );
    }

    public getOrgTagType( params ) {
        let slug = params.slug.replace( new RegExp('/', 'g'), ',' );
        slug = slug.replace( ',', '/' );
        return this.webCallGet( '', 'org_tag_type' + slug );
    }

    public getTabData( params ) {
        let slug = params.slug.replace( new RegExp('/', 'g'), ',' );
        slug = slug.replace( ',', '/' );
        slug = slug.replace(/.$/,"");
        let id = params.id;
        if ( null == id || '' == id )
            id = 0;
        return this.webCallGet( '', 'tab_data' + slug + '/' + id );
    }

    public getNotFoundPage() {
        return this.webCallGet( '', 'no_page_found' );
    }

    public getLoginContent() {
        return this.webCallGet( '', 'login_detail' );
    }

    public getTeamInfo() {
        return this.webCallGet( '', 'team_contact_info' );
    }

    public getCurrentSiwiSeason() {
        return this.webCallGet( '', 'current_siwi_season' );
    }

    public getWpTeamParticipants( params ) {
        return this.webCallGetMoreData( params, 'get_more_team');
    }

    public getTeamDetail( params ) {
        return this.webCallGet( '', 'team_detail/' + params.id );
    }

    public webCallGet( params, method ) {
        let hashParams = '9pi96rfn4tbozjx29nkkrxyfo93w2z1p';
        let options = {
            headers: new HttpHeaders({
               // 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'amx ' + hashParams,
            })
        }
        // return this.http.get( this.webserviceUrl + method + '?t=' + this.rand_time, options ).map( res => res );
        return this.http.get( this.webserviceUrl + method, options ).map( res => res );
    }

    public webCallInnerPageGet( params, method ) {
        let slug       = params.slug.replace( new RegExp('/', 'g'), ',' );
        slug           = slug.replace( ',', '/' );
        let hashParams = '9pi96rfn4tbozjx29nkkrxyfo93w2z1p';
        let options = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'amx ' + hashParams,
            })
        }
        // return this.http.get( this.webserviceUrl + method + slug + '?t=' + this.rand_time, options ).map( res => res );
        return this.http.get( this.webserviceUrl + method + slug, options ).map( res => res );
    }

    public webCallSearchData( params, method ) {
        let hashParams = '9pi96rfn4tbozjx29nkkrxyfo93w2z1p';
        var searchText = params.searchText;
        if ( searchText.indexOf( ' ' ) )
            searchText = searchText.replace( /\s+/g, '+' );
        let options = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'amx ' + hashParams,
            })
        }
        return this.http.get( this.webserviceUrl + method + '/' + searchText + '/' + params.searchArea + '/' + params.searchId, options ).map( res => res );
    }

    public webCallGetMoreData( params, method ) {
        let hashParams = '9pi96rfn4tbozjx29nkkrxyfo93w2z1p';
        let options = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'amx ' + hashParams,
            })
        }
        return this.http.get( this.webserviceUrl + method + '/' + params.offset + '/' + params.limit, options ).map( res => res );
    }
}
