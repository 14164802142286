import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
	selector      : 'app-banner',
	templateUrl   : './banner.component.html',
	encapsulation : ViewEncapsulation.None
})

export class BannerComponent implements OnInit {
	@Input() bannerData         : any;
	@Input() bannerImage        : any;
	@Input() bannerLocationDate : any;
	@Input() dateCounter        : any;
	@Input() showCounter        : any;

	constructor() {}

	ngOnInit() {}
}