<section class="component component--quote-block quote-block bck-style-{{ quoteContent.quote_background_style}} {{ quoteContent.quote_block_width }}--quote-block">
    <div class="bluebg-overlay-full"></div>
	<div class="wrapper">
		<div class="row">
			<div class="col-sm-8">
				<div class="bluebg-overlay"></div>
				<div class="quote-block-text">
					<p *ngIf="'0' == quoteContent.double_quote" [innerHtml]="quoteContent.quote_content"></p>
					<p *ngIf="'0' != quoteContent.double_quote">“{{ quoteContent.quote_content }}”</p>
					<span class="quote-author" *ngIf="'' != quoteContent.quote_author" [innerHtml]="quoteContent.quote_author"></span>
				</div>
			</div>				
		</div>
	</div>    
</section>