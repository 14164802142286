<section id="animate" class="component double-content-block" [ngClass]="'' != doubleColContent.has_background_color ? 'has_background_color' : ''">
	<div class="wrapper">
		<div class="row">
			<div class="col-sm-8 dc_col">
				<div class="row dc_row">
                    <div class="col-md-6 dc_repeater" [ngClass]="true == doubleColContent.has_background_color ? 'show_social_media' : ''" *ngFor="let dVal of doubleColContent.double_content_repeater">
                        <div class="dc_contents">
                            <div class="dc_maintitle" *ngIf="'' != dVal.double_content_main_title" [innerHtml]="dVal.double_content_main_title"></div>
                            <h3 class="dc_title" *ngIf="'' != dVal.double_content_title" [innerHtml]="dVal.double_content_title"></h3>
                            <p class="dc_description" *ngIf="'' != dVal.double_content_description" [innerHtml]="dVal.double_content_description"></p>    
                            <a *ngIf="'external' == dVal.double_content_button_type && '' != dVal.double_content_button_title" href="{{ dVal.double_content_button_link }}" target="_blank" class="link-more btn btn-large btn-circle btn-bluelight"><span class="btn-label"></span>{{ dVal.double_content_button_title }}</a>                        
                            <a *ngIf="'internal' == dVal.double_content_button_type && '' != dVal.double_content_button_title" routerLink="{{ dVal.double_content_button_link_int }}" class="link-more btn btn-large btn-circle btn-bluelight"><span class="btn-label"></span>{{ dVal.double_content_button_title }}</a>
                        </div>
                        <!-- need working-->
                        <div class="siwi-staff engage-withfigure d-flex {{ dVal.background_style_for_author == 'light-blue' ? ' bck-style-light-blue' : dVal.background_style_for_author == 'dark-blue' ? '  bck-style-dark-blue' : dVal.background_style_for_author == 'sky-blue' ? ' bck-style-sky-blue' : ' bck-style-white' }}" *ngFor="let dAuthor of dVal.author_detail">                            
                            <div class="svgcurve"></div>
                            <a class="engage-withfigureimage" routerLink="{{ dAuthor.link }}" >
                                <figure class="image-center por">
                                   <img src="{{ dAuthor.image }}" class="img-responsive thumbnail" alt="{{ dAuthor.title }}">
                                </figure>
                            </a>
                            <div class="engage-figuretext">
                                <a  routerLink="{{ dAuthor.link }}" >
                                    <div class="customer_name" [innerHtml]="dAuthor.title"></div>
                                    <div class="customer_position" *ngIf="'' != dAuthor.position" [innerHtml]="dAuthor.position"></div>
                                </a>
                                <div class="engage-author">
                                    <div class="engage-author-email org_email" *ngIf="'' != dAuthor.email"><a href="mailto:{{ dAuthor.email }}"><span class="icon icon-mail-alt"></span><span class="author-email">{{ dAuthor.email }}</span></a></div>
                                    <div class="engage-author-phone org_phone" *ngIf="'' != dAuthor.phone"><span class="icon icon-landline"></span><span class="author-phone">{{ dAuthor.phone }}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="social-media-section" *ngIf="true == dVal.social_media">
                            <div class="social_media">
                                <a *ngIf="'' != dVal.social_media.youtube_url" href="{{ dVal.social_media.youtube_url }}" class="youTube" target="_blank"><span class="social_media_images"></span><span class="social_label" [innerHtml]="dVal.social_media.youtube_title"></span></a>
                                <a *ngIf="'' != dVal.social_media.facebook_url" href="{{ dVal.social_media.facebook_url }}" class="facebook" target="_blank"><span class="social_media_images"></span><span class="social_label" [innerHtml]="dVal.social_media.facebook_title"></span></a>
                                <a *ngIf="'' != dVal.social_media.twitter_url" href="{{ dVal.social_media.twitter_url }}" class="twitter" target="_blank"><span class="social_media_images"></span><span class="social_label" [innerHtml]="dVal.social_media.twitter_title"></span></a>
                                <a *ngIf="'' != dVal.social_media.instagram_url" href="{{ dVal.social_media.instagram_url }}" class="instagram" target="_blank"><span class="social_media_images"></span><span class="social_label" [innerHtml]="dVal.social_media.instagram_title"></span></a>
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		</div>
	</div>
</section>