import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AccountServiceProvider } from '../../providers/account-service/account-service';
import { Shareddata } from '../../providers/shareddata';
import { Config } from '../../app/config';
import { SigninsighupmodalComponent } from '../signinsighupmodal/signinsighupmodal.component';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import smoothscroll from 'smoothscroll-polyfill';

@Component({
	selector      : 'app-navigation',
	templateUrl   : './navigation.component.html',
	encapsulation : ViewEncapsulation.None,
})

export class NavigationComponent implements OnInit {
	formGroup : FormGroup;	
	@Input() registrationMenu      : any = [];
	@Input() primaryMenuFirstCols  : any = [];
	@Input() primaryMenuSecondCols : any = [];
	@Input() primaryMenuThirdCols  : any = [];
	@Input() primaryMenuFourthCols : any = [];
	@Input() externalLinkMenu      : any = [];
	@Input() contactInfoMenu       : any;
	@Input() currentSearchOptionId : any = 0;
	@Input() notificationTitle     : any;
	@Input() notificationContent   : any;
	@Input() newMenuArray : any;
	public i : number;
	public searchDropDown      : any = [];
	public primaryMenu         : any = [];
	public selectedSortOptions : any;
	public searchArea          : any;
	public searchId            : any;
	public userData            : any;
	public userId              : any;
	public userName            : any;
	public accessToken         : any;	
	public observableRef2      : any;
	public active_class_parent : any;
	public active_title        : any;
	public serverPath          : any;
	public currentTime         : any;	
	public searchApi       : boolean = false;
	public headerFooterGet : boolean = false;
	public isMobile        : boolean;

	public isLoggedin : boolean = false;

	constructor (
		private router         : Router,
		private modalService   : NgbModal,
		private accountService : AccountServiceProvider,		
		public shareddata      : Shareddata,		
		private cfg            : Config,
		
	) {
		smoothscroll.polyfill();
		if ( devicePixelRatio > 1.5 && document.body.clientWidth < 767 )
            this.isMobile = true;
        else
            this.isMobile = false;		
		this.serverPath = this.cfg._apiSite || 'http://programmev2.worldwaterweek.org/';
		if ( 'userData' in window.localStorage ) {	
			this.userData = JSON.parse( window.localStorage.getItem( 'userData' ) );
			this.userId = this.userData.userId;
			this.userName = this.userData.userName;
			this.accessToken = this.userData.access_token;
			this.shareddata.setUserSession( this.userData );
			document.getElementById( 'setCookie' ).innerHTML = '<iframe src="' + this.serverPath + 'SwitchToProgramme?Token=' + this.accessToken + '&UserId=' + this.userId + '&type=1" style="display:none;" /></iframe>';
			if ( 'httpRefererDotNet' in window.localStorage ) {
				let current_url = decodeURIComponent( window.localStorage.getItem( 'httpRefererDotNet' ) );				
				window.localStorage.removeItem( 'httpRefererDotNet' );
				if ( current_url.includes( '?' ) ) {
					setTimeout(() => {
						this.router.navigateByUrl( current_url );
					}, 1000 );
			} else {
					setTimeout(() => {
					window.location.href = current_url;
					}, 1000 );
				}
			}
		}

		if ( this.shareddata.userData.expiryTime != '' && this.shareddata.userData.loggedInTime != '' && this.shareddata.userData.accessToken != '' ) {
			let currentTimeStamp = new Date();
			let loggedInTime = new Date( this.shareddata.userData.loggedInTime );
			let expiryTime = this.shareddata.userData.expiryTime * 1000;
			let loggedInExpiryTime = new Date( loggedInTime.getTime() + expiryTime );		
			if ( currentTimeStamp.getTime() >= loggedInExpiryTime.getTime() ) {
				window.localStorage.removeItem( 'userData' );
				this.userId = '';
				this.userName = '';
				this.accessToken = '';				
				this.shareddata.clearUserSession();
				window.localStorage.removeItem( 'httpReferer' );
				window.localStorage.removeItem( 'filterClick' );
			}
			this.observableRef2 = Observable.interval( 60000 ).subscribe( x => {
				let currentTimeStamp = new Date();
				if ( currentTimeStamp.getTime() >= loggedInExpiryTime.getTime() ) {	
					window.localStorage.removeItem( 'userData' );
					this.userId = '';
					this.userName = '';
					this.accessToken = '';				
					this.shareddata.clearUserSession();
					window.localStorage.removeItem( 'httpReferer' );
					window.localStorage.removeItem( 'filterClick' );
					this.observableRef2.unsubscribe();
				}
			});
		}

		this.accountService.getNewHeader().then( ( result: any ) => {
			if ( 'Ok' == result.status ) {
				this.newMenuArray     = result.new_menu;
				this.registrationMenu = result.registration_menu;
			}
		});
	}

	getHeaderMenu() {
		if ( document.getElementsByClassName( 'navigation__search' )[0].classList.contains( 'show' ) ) {
			(<HTMLInputElement>document.getElementById( 'openSearchDropdown' ) ).click();
		}
		if( this.isMobile )
			setTimeout( () => {
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				});
			}, 1 );
		var search_id;
		if ( 'siteSearch' in window.sessionStorage ) {
			let localItems = JSON.parse( sessionStorage.getItem( 'siteSearch' ) );
			search_id = localItems.search_id;
		} else
			search_id = 0;

		let option_params = {
			current_option_id : search_id,
		}
		this.currentSearchOptionId = search_id;
		if ( this.headerFooterGet == false ) {
			document.querySelector( 'header' ).classList.toggle( 'menu_loader' );
			document.querySelector( '#tagloading' ).classList.remove( 'hide' );
			this.accountService.getHeaderFooterMenu( option_params ).then( ( response: any ) => {
				if ( 'Ok' === response.status ) {
					this.headerFooterGet = true;
					this.i                = 0;					
					for ( let value of response.other_menus ) {
						if ( 0 == this.i || 3 == this.i || 6 == this.i )
							this.primaryMenuFirstCols.push( value );
						if ( 1 == this.i || 4 == this.i || 7 == this.i )
							this.primaryMenuSecondCols.push( value );
						if ( 2 == this.i || 5 == this.i || 8 == this.i )
							this.primaryMenuThirdCols.push( value );
						this.i++;
					}			
					// this.contactInfoMenu  = response.header_menu.contact_info;
					this.contactInfoMenu  = response.header_menu;
					document.getElementById( 'tagloading' ).classList.add( 'hide' );
					document.getElementsByTagName( 'header' )[0].classList.toggle( 'menu_opened' );
					document.getElementsByTagName( 'header' )[0].classList.toggle( 'menu_loader' );
				}
			});				
			this.menuActive();
		} else {
			document.querySelector( 'header' ).classList.toggle( 'menu_opened' );		
		}		
	}

	menuActive() {
		setTimeout(
			()=> {
				var elems = document.querySelectorAll( '.active-menu' );
				[].forEach.call( elems, function( el ) {
					if( el.parentElement.parentElement.previousElementSibling != null )
						el.parentElement.parentElement.previousElementSibling.classList.add( 'menu-shown' );
					if( el.parentElement.previousElementSibling != null )
						el.parentElement.previousElementSibling.classList.add( 'menu-shown' );
				});
			}, 200
		);
	}

	ngOnInit() {
		var text_search;
		if ( 'siteSearch' in window.sessionStorage ) {
			let localItems = JSON.parse( sessionStorage.getItem( 'siteSearch' ) );
			text_search = localItems.search_text;
			this.active_class_parent = localItems.search_id;
			this.active_title = localItems.search_type_title;
		} else {
			text_search = '';
			this.active_class_parent = 0;
			this.active_title = 'Everything';
		}		
		this.formGroup = new FormGroup({
			SearchText :new FormControl( text_search, [
								Validators.required,
							]),
		});
	}

	toggledSearch( event ) {
		if ( event ) {
			if ( document.getElementsByClassName( 'header ')[0].classList.contains( 'menu_opened' ) ) {
				document.getElementsByClassName( 'header ')[0].classList.remove( 'menu_opened' );
			}
			(<HTMLInputElement>document.getElementById( 'openMenuDropdown' ) ).click();
			window.setTimeout(function () { 
				document.getElementById( 'SearchText' ).focus();
			}, 0 );
			if ( this.searchApi == false ) {
				let option_params = {
					current_option_id : ( <HTMLInputElement>document.getElementById( "current_option_id" ) ).value,
				}
				this.accountService.getSearchDropDownData( option_params ).then( ( response: any ) => {
					if ( 'Ok' === response.status ) {
						this.searchApi = true;
						this.searchDropDown      = response.menu_array;
						this.selectedSortOptions = response.current_option;
						this.shareddata.setSearchOption( this.selectedSortOptions );
					}
				});
			}
		}
	}

	toggleMenu( event : any, classname : string ) {
		const target   = event.target;
		const hasClass = target.parentElement.classList.contains( classname );
		if( hasClass )
			target.parentElement.classList.remove( classname );
		else {
			var elems = document.querySelectorAll( '.' + classname );
			[].forEach.call( elems, function( el ) {
				el.classList.remove( classname );
			});
			target.parentElement.classList.add( classname );
		}
	}

	onSearchSubmit() {
		(<HTMLInputElement>document.getElementById( 'openSearchDropdown' ) ).click();
		var elems = document.querySelectorAll( '.show' );
		[].forEach.call( elems, function( el ) {
			el.classList.remove( 'show' );
		});
		var searchText = this.shareddata.searchText;		
		var element = document.getElementsByClassName( 'active-menu-search-dropdown' );
		this.searchArea = element[0].getAttribute( 'data-slug' );
		this.searchId   = element[0].getAttribute( 'data-id' );		
		let params = {
			'searchText' : searchText,
			'searchArea' : this.searchArea,
			'searchId'   : this.searchId,
		};		
		if ( this.router.url.includes( '/search' ) ) {
			var url = '/search?search_text=' + params.searchText + '&search_area=' + params.searchArea + '&search_id=' + params.searchId;
			this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
			this.router.navigateByUrl( url ));
		} else {
			this.router.navigate( ['search'], {
				queryParams: {
					search_text  : params.searchText,
					search_area : params.searchArea,
					search_id   : params.searchId,
				}
			});
		}
	}

	searchByThisOption( event, option_title, option_id ) {
		var elems = document.querySelectorAll( '.active-menu-search-dropdown' );
		[].forEach.call( elems, function( el ) {
			el.classList.remove( 'active-menu-search-dropdown' );
		});
		event.target.classList.add( 'active-menu-search-dropdown' );
		this.selectedSortOptions = option_title;
		this.shareddata.setSearchOption( this.selectedSortOptions );
		this.shareddata.setSearchOptionId( option_id );
		document.getElementById( 'openSearchFilterDropdown' ).click();
	}

	doLogout() {
		document.getElementById( 'setCookie' ).innerHTML = '<iframe src="' + this.serverPath + 'SwitchToProgramme?Token=' + this.accessToken + '&UserId=' + this.userId + '&type=2" style="display:none;" /></iframe>';
		window.localStorage.removeItem( 'userData' );
		this.userId = '';
		this.userName = '';
		this.accessToken = '';		
		this.shareddata.clearUserSession();
		window.localStorage.removeItem( 'httpReferer' );
		window.localStorage.removeItem( 'filterClick' );
		window.location.reload();
	}

	goToLoginPage( type ) {
		let current_url;
		if ( 'dashboard' === type ) {
		 	sessionStorage.setItem( 'isPageNeedLogin', JSON.stringify( 0 ) );
		 	current_url = this.serverPath + 'dashboard';
		 } else
			current_url = this.router.url;
		window.localStorage.setItem( 'httpReferer', current_url );
		const modalRef = this.modalService.open(
			SigninsighupmodalComponent,
			{
	            size: 'lg', 
	            windowClass: 'custom-modal',
	            backdrop  : 'static',
   				keyboard  : false,
	        }
        );
	}

	onSearchChange( searchValue : string ) {
		this.shareddata.setSearchText( searchValue );
	}

	closeNotification() {
		document.getElementById( 'notification-bar' ).classList.add( 'hide' );
		this.currentTime = new Date().getTime();
		window.localStorage.setItem( 'notificationCloseTime', this.currentTime );
	}

	addMenuId( object_id ) {
		if( this.isMobile )
			setTimeout( () => {
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				});
			}, 1 );
	}	
}