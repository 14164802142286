import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountServiceProvider } from '../../providers/account-service/account-service';
import { Shareddata } from '../../providers/shareddata';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSelectModule } from 'ngx-select-ex';
import { Config } from '../../app/config';

@Component({
	selector    : 'app-signinsighupmodal',
	templateUrl : './signinsighupmodal.component.html',
	providers: [Shareddata],
})

export class SigninsighupmodalComponent implements OnInit {	
	loginForm                    : FormGroup;
	public emailFilled           : boolean = false;
	public passwordFilled        : boolean = false;
	public loginErrorMessage     : boolean = false;
	public sessionOut            : boolean = false;
	public loginLoad             : boolean = false;
	public loginerrorMessageText : any;
	public returnUrl             : any;	
	signupForm                     : FormGroup;
	public FirstNameFilled         : boolean = false;
	public LastNameFilled          : boolean = false;
	public EmailAddressFilled      : boolean = false;
	public errorMessage            : boolean = false;
	public successMessage          : boolean = false;
	public successMessageEmail     : boolean = false;
	public signupLoad              : boolean = false;
	public errorMessageText        : any;
	public successMessageText      : any;
	public successMessageEmailText : any;	
	public selectedCountry         : any;
	public userData           : any;
	public userId             : any;
	public userName           : any;
    public accessToken        : any;
    public serverPath         : any;
    public is_page_with_login : any;
	public countryData : Array<NgxSelectModule>;
	public isMobile    : boolean;

	constructor(
		private route          : ActivatedRoute,
		private router         : Router,
		public formBuilder     : FormBuilder,
		private accountService : AccountServiceProvider,
		public shareddata      : Shareddata,
		private cfg            : Config,
		public activeModal     : NgbActiveModal,
	) {
		this.serverPath = this.cfg._apiSite || 'http://programmev2.worldwaterweek.org/';
		if ( devicePixelRatio > 1.5 && document.body.clientWidth < 767 )
            this.isMobile = true;
        else
            this.isMobile = false;
		/* login */
		this.route.queryParams.subscribe( params => {
	        this.sessionOut = params['sessionOut'];
	    });
	    this.route.queryParams.subscribe( params => {
	        this.returnUrl = params['returnUrl'];
	    });
	    if ( this.sessionOut )
        	this.doLogout();
        else {
        	if ( 'userData' in window.localStorage )
	            this.router.navigate(["/"]);
        }
        /* login */
        this.accountService.getCountry().then( ( dotnetresponse: any ) => {
        	if ( 'Ok' === dotnetresponse.status ) {
        		let arrCountry = [];
        		arrCountry.push( { id: 0, text: 'Select country' } );
        		dotnetresponse.CountryList.forEach(element => {
		           const certif = { id: element.Id, text: element.Name };
		           arrCountry.push( certif );
	         	});
        		this.countryData = arrCountry;
        	}
        });
        this.is_page_with_login = JSON.parse( sessionStorage.getItem( 'isPageNeedLogin' ) );
	}

	ngOnInit() {
		/* login */
		this.loginForm = this.formBuilder.group({
			email: [ '', [ Validators.required, Validators.email ] ],
			password: [ '', [ Validators.required ] ],
		});
		this.email.valueChanges.subscribe(() => {
			if ( this.email.value != '' )
				this.emailFilled = true;
			else
				this.emailFilled = false;
		});
		this.password.valueChanges.subscribe(() => {
			if ( this.password.value != '' )
				this.passwordFilled = true;
			else
				this.passwordFilled = false;
		});
		/* login */
		this.signupForm = this.formBuilder.group({
			FirstNameField: [ '', [ Validators.required ] ],
			LastNameField: [ '', [ Validators.required ] ],
			EmailField: [ '', [ Validators.required, Validators.email ] ],
		});
		this.FirstNameField.valueChanges.subscribe(() => {
			if ( this.FirstNameField.value != '' )
				this.FirstNameFilled = true;
			else
				this.FirstNameFilled = false;
		});
		this.LastNameField.valueChanges.subscribe(() => {
			if ( this.LastNameField.value != '' )
				this.LastNameFilled = true;
			else
				this.LastNameFilled = false;
		});
		this.EmailField.valueChanges.subscribe(() => {
			if ( this.EmailField.value != '' )
				this.EmailAddressFilled = true;
			else
				this.EmailAddressFilled = false;
		});
	}

	/* login */
	get email() { return this.loginForm.get( 'email' ); }
	get password() { return this.loginForm.get( 'password' ); }
	/* login */
	get FirstNameField() { return this.signupForm.get( 'FirstNameField' ); }
	get LastNameField() { return this.signupForm.get( 'LastNameField' ); }
	get EmailField() { return this.signupForm.get( 'EmailField' ); }

	doLogin() {
		this.loginLoad = true;
		let Username = this.loginForm.controls["email"].value;
		let Password = this.loginForm.controls["password"].value;
		let params = {
			'Username': Username,
			'Password': encodeURIComponent( Password ),
			'grant-type': 'password',
		};
		if ( ! this.loginForm.valid ) {
			this.loginLoad = false;
			return;
		}
		this.accountService.doUserLogin( params ).then( ( dotnetresponse: any ) => {
			if ( dotnetresponse.status !== 'ERROR' ) {
				let result = JSON.parse( dotnetresponse );
				this.loginLoad = true;
				if ( result.access_token ) {
					let loggedInTime = new Date();
					result.loggedInTime = loggedInTime;
					window.localStorage.setItem( 'userData', JSON.stringify( result ) );
					this.userData = result;
					this.userId = result.userId;
					this.userName = result.userName;
					this.accessToken = result.access_token;
					this.shareddata.setUserSession( this.userData );
					document.getElementById( 'setCookie' ).innerHTML = '<iframe src="' + this.serverPath + 'SwitchToProgramme?Token=' + this.accessToken + '&UserId=' + this.userId + '&type=1" style="display:none;" /></iframe>';
					setTimeout(() => {
						if ( 'httpReferer' in window.localStorage ) {
							let current_url = decodeURIComponent( window.localStorage.getItem( 'httpReferer' ) );						
							window.localStorage.removeItem( 'httpReferer' );
							if ( current_url.includes( '?' ) ) {
								this.router.navigateByUrl( current_url );
							} else {
								if ( current_url.includes( 'Proposal/Create' )  || current_url.includes( 'dashboard' ) ) {
									window.localStorage.setItem( 'httpRefererDotNet', current_url );
									window.location.href = this.router.url;
								} else {
									window.location.href = current_url;
								}
							}
						} else {
							if ( this.returnUrl ) {
								window.location.href = this.returnUrl;
							} else {
								this.router.navigate(["/"]);
							}
						}
					}, 1000 );
				}
			} else {
				this.loginLoad = false;
				this.loginErrorMessage = true;
				if ( '3111' == dotnetresponse.errcode ) {
					this.loginerrorMessageText = '';
				} else {
					this.loginerrorMessageText = dotnetresponse.error_description;
				}
			}
		});
	}

	doLogout() {
		document.getElementById( 'setCookie' ).innerHTML = '<iframe src="' + this.serverPath + 'SwitchToProgramme?Token=' + this.accessToken + '&UserId=' + this.userId + '&type=2" style="display:none;" /></iframe>';
		window.localStorage.removeItem( 'userData' );
		this.userId = '';
		this.userName = '';
		this.accessToken = '';		
		this.shareddata.clearUserSession();
		window.localStorage.removeItem( 'httpReferer' );
		window.localStorage.removeItem( 'filterClick' );
	}

	closeModal() {
		this.activeModal.close();
	}

	forgotPassword() {
		this.activeModal.close();
		this.router.navigate(["/forgot-password"]);
	}

	doSignUp() {
		this.signupLoad = true;
		let Firstname = this.signupForm.controls["FirstNameField"].value;
		let Lastname = this.signupForm.controls["LastNameField"].value;
		let Email = this.signupForm.controls["EmailField"].value;
		if ( 0 == this.selectedCountry ) {
			this.signupLoad = false;
			this.errorMessage = true;
			this.errorMessageText = 'Select Country';
			if( this.isMobile )
				setTimeout(() => {
		            document.getElementById( 'signuperror' ).scrollIntoView();
        		}, 1 );
			return;
		}
		let params = {
			'FirstName': Firstname,
			'LastName': Lastname,
			'Email': Email,
			'CountryId': this.selectedCountry,
		};
		if ( ! this.signupForm.valid ) {
			this.signupLoad = false;
			return;
		}
		this.accountService.doUserSignUp( params ).then( ( dotnetresponse: any ) => {
			this.signupLoad = true;
			if ( 'Ok' === dotnetresponse.status ) {
				this.signupLoad = false;
				this.successMessage = true;
				this.successMessageText = dotnetresponse.message;
				this.errorMessage = false;
				this.errorMessageText = '';
				if( this.isMobile )
					setTimeout(() => {
			            document.getElementById("signupsuccess").scrollIntoView();
	        		}, 1 );
			} else {
				if ( 'ERROR' == dotnetresponse.status && '3111' == dotnetresponse.errcode ) {
					this.errorMessage = true;
					this.errorMessageText = '';
				} else {
					this.errorMessage = true;
					this.errorMessageText = dotnetresponse.message;
				}
				this.signupLoad = false;
				this.successMessage = false;
					this.successMessageText = '';
				if( this.isMobile ) {
					setTimeout( () => {
						document.getElementById( 'signuperror' ).scrollIntoView();
					}, 1 );
				}
			}
		});
	}

	handleChange(event):void {
		this.selectedCountry = event;
	}

}