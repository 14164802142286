<section *ngIf="newsData?.news_type === 'home'" id="news" class="component component--news news text-color-dark">
	<div class="wrapper">
		<h2 class="component__title text-center title-small" [innerHtml]="newsData?.news_block_title"></h2>
		<div class="masonry-section masonry-section-js news-grid row">
			<div class="grid-sizer-3"></div>			
			<div class="col-12 col-sm-6 col-md-4 masonry-item news-grid-item" *ngFor="let nVal of newsData?.data;trackBy: trackByFn">
				<div class="news-grid__item">
					<div class="svg-triangle">
						<svg preserveAspectRatio="none" viewBox="0 0 100 100" width="11px" height="11px">
							<polygon points="0,100 100,100 0,0" fill="#ffffff"></polygon>
						</svg>
					</div>
					<a class="news__link" href="{{ nVal.news_link }}" target="_blank" *ngIf="nVal.news_link_ext == 'yes'">
						<figure>
							<img [defaultImage]="nVal.news_image" [lazyLoad]="nVal.news_image" alt="{{ nVal.news_title }}" class="img-responsive" loading="lazy" width="342" height="229"  *ngIf="nVal.news_image_show != 0 && nVal.news_image != ''"/>
						</figure>
						<div class="news__content">
							<span class="news_date" *ngIf="'' != nVal.news_category">{{ nVal.news_category }} . {{ nVal.news_date }}</span>
							<span class="news_date" *ngIf="'' == nVal.news_category" [innerHtml]="nVal.news_date"></span>
							<h3 class="h4" [innerHtml]="nVal.news_title"></h3>
							<p *ngIf="nVal.news_excerpt" [innerHtml]="nVal.news_excerpt"></p>
						</div>
					</a>
					<a class="news__link" routerLink="/{{ nVal.news_link }}" *ngIf="nVal.news_link_ext != 'yes'">
						<figure>
							<img [defaultImage]="nVal.news_image" [lazyLoad]="nVal.news_image" alt="{{ nVal.news_title }}" class="img-responsive" loading="lazy" width="342" height="229" *ngIf="nVal.news_image_show != 0 && nVal.news_image != '' && false == isLighthouse" />
							<img [defaultImage]="nVal.news_image_thumbnail" [lazyLoad]="nVal.news_image_thumbnail" alt="{{ nVal.news_title }}" class="img-responsive" loading="lazy" width="150" height="150" *ngIf="nVal.news_image_show != 0 && nVal.news_image_thumbnail != '' && true == isLighthouse" />
						</figure>
						<div class="news__content">
							<span class="news_date" *ngIf="'' != nVal.news_category">{{nVal.news_category}} . {{nVal.news_date}}</span>
							<span class="news_date" *ngIf="'' == nVal.news_category" [innerHtml]="nVal.news_date"></span>
							<h3 class="h4" [innerHtml]="nVal.news_title"></h3>
							<p *ngIf="nVal.news_excerpt" [innerHtml]="nVal.news_excerpt"></p>
						</div>
					</a>
				</div>
			</div>
		</div>
		<div class="text-center link-wrapper">
			<a class="link-more btn btn-large btn-bluelight btn-circle" href="{{ newsData?.news_block_link.url }}" target="{{ newsData?.news_block_link.target }}">{{ newsData?.news_block_link.title }} <span><i class="icon-arrow-right"></i></span></a>
		</div>
	</div>
</section>