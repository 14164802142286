<section id="conference" class="component component--conference conference text-color-dark">
	<div class="wrapper">
		<h4 class="component__title text-center title-small">{{ conferenceWpData?.data?.title }} <span><a href="javascript:void(0);" (click)="refreshConference( conferenceSeason, keynoteLimit, fallConclusionLimit )" class="conference__refresh"><i class="icon-refresh"></i></a></span></h4>
		<ngx-masonry [options]="myOptions" class="masonry-section masonry-section-js conf-grid row">
			<div class="grid-sizer"></div>
			<div ngxMasonryItem *ngFor="let confitem of conferenceDotNetData;let first = first;let last = last; let odd = odd; let even = even" class="conf-grid-item masonry-item conference__type-{{ confitem.type | lowercase }} {{( first || last)  ? 'col-12 col-md-6' : 'col-6 col-md-3'}}">
				<a class="news__link" href="{{ confitem.type == 'Keynote' ? (confitem.slug_type == 'external' ? confitem.slug.url : confitem.slug )  : confitem.slug }}" target="{{ confitem.type == 'Keynote' ? confitem.slug.target : '_self' }}" *ngIf="confitem.type == 'Keynote'">
					<div class="block-tag">
						<b class="tag-head"></b>
						<span [innerHtml]="confitem.type"></span>
					</div>
					<figure class="conference_image" *ngIf="confitem.image != ''">
						<img masonryLazy  loading="lazy" alt="{{ confitem.title }}" class="img-responsive" [defaultImage]=" ( first || last) ? confitem.largeimage : confitem.image" [lazyLoad]=" ( first || last) ? confitem.largeimage : confitem.image" loading="lazy" width="{{confitem.imageWidth}}" height="{{confitem.imageHeight}}" />
					</figure>
					<div class="news__content">
						<h3 class="h4" [innerHtml]="confitem.title | titlecase"></h3>
						<div *ngIf="confitem?.description" [innerHTML]="confitem?.description | safe:'preamble'">
							<em [innerHtml]="confitem?.description"></em><br/>
							<span *ngIf="confitem?.country" [innerHtml]="confitem?.country"></span>
						</div>
					</div>
				</a>
				<a class="news__link  {{ odd ? 'odd-list' : 'even-list' }}" routerLink="{{ 'Organization' == confitem.type ? '/community/organization/' : 'Speaker' == confitem.type ? '/community/participant/' : '/event/' }}{{ confitem.slug }}" *ngIf="confitem.type != 'Keynote'" (click)="storeColor(odd ? 'odd-list' : 'even-list')">
					<div class="news__figure">
						<figure class="conference_image" *ngIf="confitem.image != ''">
							<img  masonryLazy alt="{{ confitem.title }}" class="img-responsive" [defaultImage]="(first || last) ? confitem.largeimage : confitem.image" [lazyLoad]="(first || last) ? confitem.largeimage : confitem.image" loading="lazy" width="{{confitem.imageWidth}}" height="{{confitem.imageHeight}}" />
						</figure>
					</div>
					<div class="news__content">
						<span class="news_date" *ngIf="confitem?.eventDate && confitem.type != 'Organization' && confitem.eventDate.length == 1"><i class="icon-clock"></i> {{ confitem.eventDate }}{{ confitem.room ? ', '  : '' }}{{ confitem.room }}</span>
						<span class="news_date" *ngIf="confitem?.eventDate && confitem.type != 'Organization' && confitem.eventDate.length > 1"><i class="icon-clock"></i>
							<span *ngFor="let val of confitem.eventDate;" [innerHtml]="val"></span>
							<span *ngIf="confitem.room">, {{ confitem.room }}</span>
						</span>
						<h3 class="h4" [innerHtml]="confitem.title | titlecase"></h3>
						<span class="event_status" *ngIf="null != confitem.online">
							<span>
								<span *ngIf="'online' == confitem.online"><span class="icon icon-cloud"></span>Online session</span>
								<span *ngIf="'on-site' == confitem.online"><span class="icon icon-map-pin"></span>On-site session</span>
								<span *ngIf="'both' == confitem.online"><span class="icon icon-cloud"></span><span class="icon icon-map-pin"></span>Online & On-site session</span>
							</span>
						</span>
						<p *ngIf="confitem?.description" [innerHTML]="confitem?.description | safe:'preamble'"></p>
					</div>
				</a>
			</div>
		</ngx-masonry>
		<div class="text-center link-wrapper show" id="conference__showmore">
			<a class="link-more btn btn-large btn-circle btn-bluelight" href="{{ conferenceWpData?.block_link?.url }}" target="{{ conferenceWpData?.block_link?.target }}">{{ conferenceWpData?.block_link?.title }} <span><i class="icon-arrow-right"></i></span></a>
		</div>
	</div>
</section>