<section id="partners" class="component component--partners partners text-color-dark" [style.background-color]="partnerBlockData?.partner_background_color">
	 <div class="wrapper">
		<h2 class="component__title text-center title-small" *ngIf="'' != partnerBlockData?.block_title" [innerHtml]="partnerBlockData?.block_title"></h2>
		<div class="partners__block-wrapper">
			<div class="partners__block-repeater" *ngFor="let pdVal of partnerData?.partner_data; let i = index">
				<h3 [innerHtml]="pdVal.name"></h3>
				<p *ngIf="pdVal.description" [innerHtml]="pdVal.description"></p>
				<!-- <div class="figure-wrapper row partner-grid grid-partner-{{i}}"> -->
				<div class="figure-wrapper row grid-partner-{{i}}">
					<!-- <div class="grid-sizer-5"></div> -->
					<div *ngFor="let pdnVal of pdVal.partners" class="partners__block-images col-12 col-md-3 col-sm-4 masonry-item">
						<a href="{{ fix_url( pdnVal.link ) }}" target="_blank" *ngIf="pdnVal.link != ''"  class="partners__block-link-wrap">
							<figure class="image-wrapper-partner">
							<img src="{{ pdnVal.logo }}" alt="{{ pdnVal.name }}" loading="lazy" width="{{ pdnVal.imageWidth }}" height="{{ pdnVal.imageHeight }}">
							</figure>
							<span class="partner-block-detail" [innerHtml]="pdnVal.name"></span>
						</a>
						<a href="javascript:void" *ngIf="pdnVal.link == ''" style="cursor: default;" class="partners__block-link-wrap">
							<figure class="image-wrapper-partner">
							<img src="{{ pdnVal.logo }}" alt="{{ pdnVal.name }}" loading="lazy" width="{{ pdnVal.imageWidth }}" height="{{ pdnVal.imageHeight }}">
							</figure>
							<span class="partner-block-detail" [innerHtml]="pdnVal.name"></span>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>