<div class="home-page__contents" [ngClass]="loadButton?'page-loading':''" *ngIf="!error_message_show">
	<section class="home-page__section" *ngFor="let val of result; let i = index;">
		<app-banner *ngIf="val.acf_fc_layout == 'banner_block'" [bannerData]="banner[ i ]" [bannerLocationDate]="banner_location_date[ i ]" [dateCounter]="dateCounter[ i ]" [showCounter]="show_data_counter" [bannerImage]="banner_image[i]"><link rel="preload" as="image" [href]="bannerImage"></app-banner>
		<app-three-column *ngIf="val.acf_fc_layout == 'three_column_block'" [threeColumnData]="three_column_block[ i ]"></app-three-column>
		<app-news *ngIf="val.acf_fc_layout == 'news_block'" [newsData]="news[ i ]"></app-news>
		<app-info *ngIf="val.acf_fc_layout == 'info_block'" [infoData]="info[ i ]"></app-info>
		<app-conference *ngIf="val.acf_fc_layout == 'conference_block'" [conferenceWpData]="conference[ i ]" [conferenceDotNetData]="conference_data" [conferenceSeason]="conference_season" [keynoteLimit]="keynote_limit" [fallConclusionLimit]="fall_conclusion_limit"></app-conference>
		<app-venue *ngIf="val.acf_fc_layout == 'venue_block'" [venueData]="venue[ i ]"></app-venue>
		<app-partner *ngIf="val.acf_fc_layout == 'partners_block'" [partnerData]="partners_data" [partnerBlockData]="partners[ i ]"></app-partner> 
		<app-newsletter  id="app-newsletter_block" *ngIf="val.acf_fc_layout == 'newsletter_block'" [newsletterData]="newsletter_data[ i ]"></app-newsletter>
		<app-news-home *ngIf="val.acf_fc_layout == 'home_news'" [homeNewsContent]="home_news_data[ i ]"></app-news-home>
		<app-home-wp-engage *ngIf="val.acf_fc_layout == 'wp_engage_block'" [wpEngageContent]="wp_engage_data[ i ]"></app-home-wp-engage>
		<app-home-fact *ngIf="val.acf_fc_layout == 'fact_block'" [factContent]="fact_data[ i ]"></app-home-fact>
		<app-home-quote *ngIf="val.acf_fc_layout == 'quote_block'" [quoteContent]="quote_data[ i ]"></app-home-quote>
		<app-home-double-content *ngIf="val.acf_fc_layout == 'double_content_column'" [doubleColContent]="double_content_data[ i ]"></app-home-double-content>
		<app-home-file *ngIf="val.acf_fc_layout == 'file_block'" [fileContent]="file_data[ i ]"></app-home-file>
		<app-accordion *ngIf="val.acf_fc_layout == 'accordion_block'" [accordionContent]="accordion_data[ i ]"></app-accordion>
	</section>
</div>
<div class="page-loader" *ngIf="loadButton">
	<span><span class="loading__circle loading__circle-small"></span></span>
</div>
<div class="alert-login error-message" id="error-message" *ngIf="error_message_show">
	<div class="wrapper">
		<span><p [innerHtml]="error_message"></p></span>
	</div>
</div>