import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient, HttpParams } from '@angular/common/http';
import { AccountServiceProvider } from '../../providers/account-service/account-service';

interface MailChimpResponse {
  result: string;
  msg: string;
}

@Component({
  selector      : 'app-newsletter',
  templateUrl   : './newsletter.component.html',
	encapsulation : ViewEncapsulation.None
})

export class NewsletterComponent implements OnInit {
  formGroup : FormGroup;
  public Emailfilled         : boolean = false;
  public Checkboxfilled : boolean = false;
  public saveUsername : any;
  public success_show : boolean = false;
  public error_show : boolean = false;
  public error_msg : any;
  public success_msg : any;
  public sendButton         : boolean = false;
  @Input() newsletterData : any;

  constructor(
    public formBuilder     : FormBuilder,
    private accountService : AccountServiceProvider,
    private http: HttpClient
  ) {}

  emailControl = new FormControl('', [
		Validators.required,
		Validators.email,
	]);
	nameControl = new FormControl('', [
		Validators.required
	]);

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      acceptTerms: [ false, [ Validators.required ] ],
			EMAIL: [ '', [ Validators.required, Validators.email ] ],
      Name: [],
		});

    this.EMAIL.valueChanges.subscribe(() => {
			if ( this.EMAIL.value != '' )
				this.Emailfilled = true;
			else
				this.Emailfilled = false;
		});

    this.acceptTerms.valueChanges.subscribe(() => {
      if ( this.acceptTerms.value != true )
				this.Checkboxfilled = true;
			else {
				this.Checkboxfilled = false;
      }
		});
  } 
  get EMAIL() {
    return this.formGroup.get('EMAIL');
  }
  get acceptTerms() {
    return this.formGroup.get('acceptTerms');
  }
  get Name() {
    return this.formGroup.get('Name');
  }

  submit() {
    if (this.Checkboxfilled === false && this.Emailfilled === true ) {
      this.sendButton = true;
      const params = new HttpParams()
        .set('FNAME', this.formGroup.value.Name)
        .set('EMAIL', this.formGroup.value.EMAIL)
        .set('MMERGE3', 'I agree to receive regular emails about the World Water Week event and related issues')
        .set('id', 'c10f8b6e27')
        .set('u', '1670ed7dfacd0b6a61d525a16')
        .set('b_1670ed7dfacd0b6a61d525a16_c10f8b6e27', ''); // hidden input name
      const mailChimpUrl = 'https://siwi.us1.list-manage.com/subscribe/post-json?u=1670ed7dfacd0b6a61d525a16' + params.toString();
      // 'c' refers to the jsonp callback param key. This is specific to Mailchimp
      this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c').subscribe(response => {
        if (response.result && response.result !== 'error') {
          this.success_show = true;
          this.error_show = false;
          this.success_msg = response.msg;
          this.formGroup.reset();
          this.Checkboxfilled = false;
        }
        else {
          this.success_show = false;
          this.error_show = true;
          this.error_msg = response.msg;
        }
        this.sendButton = false;
      }, error => {
        this.sendButton = false;
        this.success_show = false;
          this.error_show = true;
          this.error_msg = error;
      });      
    }
  }
}