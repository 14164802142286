import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AccountServiceProvider } from '../../providers/account-service/account-service';
import Masonry from 'masonry-layout';
import * as imagesLoaded from 'src/assets/js/imagesloaded.pkgd.min.js';

@Component({
	selector      : 'app-news',
	templateUrl   : './news.component.html',
	encapsulation : ViewEncapsulation.None,
	host: {
		'(window:resize)': 'onResize($event)'
	}
})

export class NewsComponent implements OnInit {
	@Input() newsData  : any;
	@Input() newsLimit : any;
	@Input() newsArray : any = [];
	public loadMoreButton : boolean = true;
	public isMobile       : boolean;
	public isLighthouse       : boolean = false;

	constructor(
		private router         : Router,
		private accountService : AccountServiceProvider,		
	) {
		if ( window.navigator.userAgent.includes('Lighthouse') ) {
			this.isLighthouse = true;
		}
		if ( devicePixelRatio > 1.5 && document.body.clientWidth < 767 )
            this.isMobile = true;
        else
            this.isMobile = false;
		/* routing */
		this.router.routeReuseStrategy.shouldReuseRoute = function () {
      		return false; 
    	};
    	/* routing */
    	this.loadMoreButton = false;
	}

	ngOnInit() {}

	ngAfterViewInit() {
		if ( false === this.isMobile )
			setTimeout (
				() => {
					this.addMasonry();
				}, 300
			);
	}

	onResize(event) {
		this.addMasonry();
	}

	addMasonry() {
		if ( document.querySelector( '.news-grid' ) ) {
			var newsElem = document.querySelector( '.news-grid' );
			var newsElemmsnry = new Masonry( newsElem, {
				itemSelector: '.news-grid-item',
				columnWidth: '.grid-sizer-3',
				horizontalOrder: true,
				percentPosition: true,
			});
			imagesLoaded( newsElem ).on( 'progress', function() {
				newsElemmsnry.layout();
			});
		}
	}

	trackByFn(index, item) {
	    return item.id;
  	}
}