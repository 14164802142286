import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
	selector      : 'app-home-double-content',
	templateUrl   : './home-double-content.component.html',
	encapsulation : ViewEncapsulation.None,
})
export class HomeDoubleContentComponent implements OnInit {
	@Input() doubleColContent : any;
	constructor() {}
	ngOnInit(): void {}
}