<section id="animate" class="component component--banner bg-color-dark text-color-light large-screen">
	<img [src]="bannerImage" class="img-responsive" height="{{bannerData.banner_image.height}}" width="{{bannerData.banner_image.width}}" fetchpriority="high"/>
	<div class="wrapper">
		<aside class="wrapper-648">
			<p class="dated">{{ bannerLocationDate }}</p>
			<h1 [innerHtml]="bannerData?.banner_title"></h1>
			<p class="ingress" [innerHtml]="bannerData?.banner_content"></p>
		</aside>
		<div class="counter with-block-tag hide" *ngIf="showCounter">
			<div class="block-tag">				
				<b class="tag-head"></b>
				<span [innerHtml]="dateCounter?.before_counter_text"></span>
			</div>
			<div class="counter__timer">
				<div class="counter__text" [innerHtml]="dateCounter?.after_counter_text"></div> 
				<div class="time-counter">
					<div><span id="counter-days"></span>Days</div>
					<div><span id="counter-hours"></span>Hours</div>
					<div><span id="counter-minutes"></span>Minutes</div>
					<div><span id="counter-seconds"></span>Seconds</div>					
				</div>				
				<div class="counter__btn" *ngIf="dateCounter?.counter_link != ''">
					<a href="{{ dateCounter?.counter_link?.url }}" target="{{ dateCounter?.counter_link?.target }}" class="btn btn-bluelight btn-block btn-circle" [innerHtml]="dateCounter?.counter_link?.title"> <i class="icon-arrow-right"></i></a>
				</div>
			</div>
		</div>
	</div>
</section>