<section id="info-menu" class="component component--info-menu text-color-dark">
	<div class="wrapper">
		<div class="row">
			<div class="col-4 menu-wrapper" ngbDropdown [autoClose]='true' autoClose="outside"  *ngFor="let tcVal of threeColumnData.three_column_block; trackBy: trackByFn">
				<a href="javascript:void(0);" ngbDropdownToggle class="menu-handler">
					<div class="menu__link">
						<h4>{{ tcVal.tcb_title }} <span><i class="icon-arrow-down"></i></span></h4>
						<p [innerHtml]="tcVal.tcb_info"></p>
					</div>
					<span class="menu__cover"></span>
				</a>
				<div class="navigation__sub" ngbDropdownMenu>
					<div class="wrapper">
						<div class="row">
							<div class="col-12 col-sm-8 content-wrapper">
								<div class="content">
									<h4 *ngIf="tcVal.left_column_content.tcb_lc_title != ''" [innerHtml]="tcVal.left_column_content.tcb_lc_title"></h4>

									<div class="preamble" [innerHtml]="tcVal.left_column_content.tcb_lc_content | safe:'preamble'"></div>
								</div>
							</div>
							<div class="col-12 col-sm-4 content-wrapper btn-wrapper">
								<div class="btn-links" *ngIf="tcVal.right_column_links.tcb_rc_links != ''">
									<div class="link" *ngFor="let rclVal of tcVal.right_column_links.tcb_rc_links; trackBy: trackByFn">
										<a class="btn btn-block btn-circle" href="{{ rclVal.tcb_rc_link.url }}" target="{{ rclVal.tcb_rc_link.target }}">
											{{ rclVal.tcb_rc_link.title }}
											<span><i class="icon-arrow-right"></i></span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<span class="divider">
				<b class="three-column-separator"></b>
			</span>
		</div>
	</div>
</section>