import { NgModule, ModuleWithProviders  } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppCustomPreloader } from './app-routing-loader';

import { HomeComponent } from './home/home.component';

const routes: Routes = [
	{ 
		path: '404',
		loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule),
	},
	{
		path: '',
		component: HomeComponent
	},
	/*{
		path: 'favourites',
		loadChildren: () => import('./favourites/favourites.module').then(m => m.FavouritesModule),
	},*/
	{
		path: 'logout',
		loadChildren: () => import('./logout/logout.module').then(m => m.LogoutModule),
	},
	// {
	// 	path: 'user-login',
	// 	loadChildren: () => import('./user-login/user-login.module').then(m => m.UserLoginModule),
	// },
	// {
	// 	path: 'user-login?returnUrl=:slug',
	// 	loadChildren: () => import('./user-login/user-login.module').then(m => m.UserLoginModule),
	// },
	{
		path: 'forgot-password',
		loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
	},
	{
		path: 'reset-password',
		loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
	},
	{
		path: 'event/:slug',
		loadChildren: () => import('./event-detail/event-detail.module').then(m => m.EventDetailModule),
		data: { preload: true, loadAfterSeconds: 10 },
	},
	{
		path: 'engage/before-you-apply/:slug',
		loadChildren: () => import('./page/page.module').then(m => m.PageModule),
		data: { preload: true, loadAfterSeconds: 10 },
	},
	{
		path: 'engage/for-convenors/:slug',
		loadChildren: () => import('./page/page.module').then(m => m.PageModule),
		data: { preload: true, loadAfterSeconds: 10 },
	},
	{
		path: 'engage/:slug/:slug',
		loadChildren: () => import('./engage-detail/engage-detail.module').then(m => m.EngageDetailModule),
		data: { preload: true, loadAfterSeconds: 10 },
	},
	/*{
		path: 'community/participant/:slug',
		loadChildren: () => import('./community-detail/community-detail.module').then(m => m.CommunityDetailModule),
		data: { preload: true, loadAfterSeconds: 10 },
	},
	{
		path: 'community/organization/:slug',
		loadChildren: () => import('./community-detail/community-detail.module').then(m => m.CommunityDetailModule),
		data: { preload: true, loadAfterSeconds: 10 },
	},
	{
		path: 'community/media/:slug',
		loadChildren: () => import('./community-detail/community-detail.module').then(m => m.CommunityDetailModule),
		data: { preload: true, loadAfterSeconds: 10 },
	},
	{
		path: 'community/partner/:slug',
		loadChildren: () => import('./community-detail/community-detail.module').then(m => m.CommunityDetailModule),
		data: { preload: true, loadAfterSeconds: 10 },
	},*/
	{
		path: 'news/:slug',
		loadChildren: () => import('./news-detail/news-detail.module').then(m => m.NewsDetailModule),
		data: { preload: true, loadAfterSeconds: 10 },
	},
	{
		path: 'search',
		loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
	},
	{
		path: 'programme/schedule',
		loadChildren: () => import('./programme/programme.module').then(m => m.ProgrammeModule),
		data: { preload: true, loadAfterSeconds: 10 },
	},
	{
		path: 'programme/schedule?tg=:number',
		loadChildren: () => import('./programme/programme.module').then(m => m.ProgrammeModule),
		data: { preload: true, loadAfterSeconds: 10 },
	},
	{
		path: 'programme/schedule?dn=:number',
		loadChildren: () => import('./programme/programme.module').then(m => m.ProgrammeModule),
		data: { preload: true, loadAfterSeconds: 10 },
	},
	{
		path: 'programme/schedule?et=:number',
		loadChildren: () => import('./programme/programme.module').then(m => m.ProgrammeModule),
		data: { preload: true, loadAfterSeconds: 10 },
	},
	{
		path: 'programme/schedule?gs=true',
		loadChildren: () => import('./programme/programme.module').then(m => m.ProgrammeModule),
		data: { preload: true, loadAfterSeconds: 10 },
	},
	{ /* for archive */
		path: 'programme/schedule/:slug',
		loadChildren: () => import('./programme/programme.module').then(m => m.ProgrammeModule),
		data: { preload: true, loadAfterSeconds: 10 },
	},
	{
		path: 'programme/exhibitors',
		loadChildren: () => import('./programme/programme.module').then(m => m.ProgrammeModule),
		data: { preload: true, loadAfterSeconds: 10 },
	},
	{
		path: 'programme/browse-topics',
		loadChildren: () => import('./programme/programme.module').then(m => m.ProgrammeModule),
		data: { preload: true, loadAfterSeconds: 10 },
	},
	{
		path: 'programme/expo',
		loadChildren: () => import('./programmexpo/programmexpo.module').then(m => m.ProgrammexpoModule),
		data: { preload: true, loadAfterSeconds: 5 },
	},
	{
		path: 'team/:slug',
		loadChildren: () => import('./team-detail/team-detail.module').then(m => m.TeamDetailModule),
		data: { preload: true, loadAfterSeconds: 10 },
	},	
	{ 
		path: ':slug',
		loadChildren: () => import('./page/page.module').then(m => m.PageModule),
		data: { preload: true, loadAfterSeconds: 10 },
	},
	{ 
		path: ':slug/:slug',
		loadChildren: () => import('./page/page.module').then(m => m.PageModule),
		data: { preload: true, loadAfterSeconds: 10 },
	},
	{ 
		path: ':slug/:slug/:slug',
		loadChildren: () => import('./page/page.module').then(m => m.PageModule),
		data: { preload: true, loadAfterSeconds: 10 },
	},	
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			onSameUrlNavigation: 'reload',
			preloadingStrategy: AppCustomPreloader,
		})
	],
	exports: [
		RouterModule
	],
	providers: [
		AppCustomPreloader
	]
})

export class AppRoutingModule { }
