<section class="innerPage-block fact-block component component--file-block file-block">
    <div class="wrapper contentWrapper">
            <div class="row">
                <div class="col-sm-8">
                    <div class="subTitleDiv" *ngIf="'' != fileContent.file_block_title"><h2 [innerHtml]="fileContent.file_block_title"></h2></div>
                    <div class="projectDetailPage-doclist" *ngFor="let fVal of fileContent.file_block_repeater">
                        <div class="editor-content">
                            <h3 *ngIf="'' != fVal.link_file_title" [innerHtml]="fVal.link_file_title"></h3>
                            <p *ngIf="'file' == fVal.file_link_type && '' != fVal.upload_link_file"><a href="{{ fVal.upload_link_file.url }}" target="_blank"><span class="docHead" [innerHtml]="fVal.upload_link_file.title"></span></a></p>
                            <p *ngIf="'internal' == fVal.file_link_type && '' != fVal.file_add_link_int"><a routerLink="{{ fVal.file_add_link_int }}"><span class="docHead" [innerHtml]="fVal.file_add_link.title"></span>{{ fVal.file_int_ext_link_title }}</a></p>
                            <p *ngIf="'external' == fVal.file_link_type && '' != fVal.file_add_link"><a href="{{ fVal.file_add_link }}" target="_blank"><span class="docHead" [innerHtml]="fVal.file_add_link.title"></span>{{ fVal.file_int_ext_link_title }}</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</section>