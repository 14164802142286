<section class="innerPage-block accordion-block component component--accordion-block accordion-block">
    <div class="wrapper contentWrapper">
        <div class="row">
            <div class="col-sm-8">
                <h2 class="block_title" *ngIf="'' != accordionContent.block_title" [innerHtml]="accordionContent.block_title"></h2>
                <div class="accordion">
                    <div class="accordion__repeater" *ngFor="let aVal of accordionContent.accordion_data;let i = index">
                        <label class="accordion__controller" (click)="accordion( $event, 'accordion__opened' )">
                            <h3 class="lc-head" *ngIf="'' != aVal.accordion_title" [innerHtml]="aVal.accordion_title"></h3>
                            <span class="plusminus"></span>
                        </label>
                        <div class="accordion__content accordionDiv">
                            <div class="accordion_header_content" *ngIf="'' != aVal.accordion_header_content && 'content' != aVal.accordion_data_type" [innerHtml]="aVal.accordion_header_content"></div>
                            <div class="row row-with-link-staff" *ngIf="'members' == aVal.accordion_data_type">
                                <div *ngFor="let mVal of aVal.data" class="grid-item-msnry accordian-inner-col link-staff">                                    
                                    <a routerLink="{{ mVal.link }}">
                                        <figure class="image-center por">
                                            <img src="{{ mVal.image }}" class="img-responsive" alt="{{ mVal.name }}">
                                        </figure>
                                        <div class="staff_details">
                                            <div class="staff-detail-name" [innerHtml]="mVal.name"></div>
                                            <span class="staff-detail-organisation" *ngIf="'' != mVal.position" [innerHtml]="mVal.position"></span>
                                            <p *ngIf="'' != mVal.text" [innerHtml]="mVal.text"></p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div *ngIf="'press_release' == aVal.accordion_data_type" class="row grid-msnry">
                                <div class="col-md-6 news-publications-item grid-item-msnry" *ngFor="let pVal of aVal.data">
                                    <a routerLink="{{ pVal.news_link}}" class="block_link" *ngIf="'no' == pVal.news_link_ext || null == pVal.news_link_ext">
                                        <figure class="image-center por">
                                            <img src="{{ pVal.news_image }}" class="img-responsive" alt="{{ pVal.news_title }}">
                                        </figure>
                                        <div class="staff_details">
                                            <div class="staff-detail-name" [innerHtml]="pVal.news_title"></div>
                                            <p *ngIf="'' != pVal.news_excerpt" [innerHtml]="pVal.news_excerpt"></p>
                                        </div>
                                    </a>
                                    <a href="{{ pVal.news_link}}" class="block_link" *ngIf="'yes' == pVal.news_link_ext">
                                        <figure class="image-center por">
                                            <img src="{{ pVal.news_image }}" class="img-responsive" alt="{{ pVal.news_title }}">
                                        </figure>
                                        <div class="staff_details">
                                            <div class="staff-detail-name" [innerHtml]="pVal.news_title"></div>
                                            <p *ngIf="'' != pVal.news_excerpt" [innerHtml]="pVal.news_excerpt"></p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div *ngIf="'siwi_in_media' == aVal.accordion_data_type">
                                <div class="accordion-media" *ngFor="let smVal of aVal.accordion_media_data">
                                    <div *ngIf="'' != smVal.siwi_media_link">
                                        <a href="{{ smVal.siwi_media_link }}" target="_blank">
                                            <span class="accordion-media-date news-date related-block-date">
                                                <span class="textCap" *ngIf="'' != smVal.siwi_media_date">{{ smVal.siwi_media_date }}</span>
                                                <span *ngIf="'' != smVal.siwi_media_date && '' != smVal.siwi_media_magazine_name"> - </span>
                                                <span class="textupper bluebold" *ngIf="'' != smVal.siwi_media_magazine_name" [innerHtml]="smVal.siwi_media_magazine_name"></span>
                                            </span>
                                            <h3 *ngIf="'' != smVal.siwi_media_title" [innerHtml]="smVal.siwi_media_title"></h3>
                                        </a>
                                    </div>
                                    <div *ngIf="'' == smVal.siwi_media_link">
                                        <span class="accordion-media-date news-date related-block-date">
                                            <span class="textCap" *ngIf="'' != smVal.siwi_media_date">{{ smVal.siwi_media_date }}</span>
                                            <span *ngIf="'' != smVal.siwi_media_date && '' != smVal.siwi_media_magazine_name"> - </span>
                                            <span class="textupper bluebold" *ngIf="'' != smVal.siwi_media_magazine_name" [innerHtml]="smVal.siwi_media_magazine_name"></span>
                                        </span>
                                        <h3 *ngIf="'' != smVal.siwi_media_title" [innerHtml]="smVal.siwi_media_title"></h3>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="'ask_expert' == aVal.accordion_data_type">
                                <div class="grid-15" *ngIf="'' != aVal.data">
                                    <div class="accordionDivContents row">
                                        <div class="col-lg-6" *ngFor="let eVal of aVal.data">
                                            <div class="engage-withfigure d-flex bck-style-light-blue">
                                                <div class="svgcurve"></div>
                                                <div class="bck-style-inner">
                                                    <a routerLink="{{ eVal.link}}" class="block_link">
                                                        <figure class="image-center por">
                                                            <img src="{{ eVal.image }}" class="img-responsive" alt="{{ eVal.name }}">
                                                        </figure>
                                                    </a>
                                                    <div class="engage-figuretext">
                                                        <a routerLink="{{ eVal.link}}" class="block_link">
                                                            <div class="customer_name">{{ eVal.name }}</div>
                                                            <div class="customer_position" *ngIf="'' != eVal.position" [innerHtml]="eVal.position"></div>
                                                        </a>
                                                        <div class="engage-author">
                                                            <div class="engage-author-phone org_phone" *ngIf="'' != eVal.phone"><span class="icon icon-landline"></span><span class="author-phone">{{ eVal.phone }}</span></div>
                                                            <div class="engage-author-email org_email" *ngIf="'' != eVal.email"><a href="mailto:{{ eVal.email }}"><span class="icon_mail icon-email "></span><span class="author-email">{{ eVal.email }}</span></a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="'content' == aVal.accordion_data_type">
                                <div class="editor-content" *ngIf="'' != aVal.accordion_content" [innerHtml]="aVal.accordion_content"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>