<section id="info" class="component component--info-block info-block text-color-dark" [style.background-color]="infoData?.block_color">
	<div class="wrapper">
		<div class="info-block__wrapper" inViewport [inViewportOptions]="{ threshold: [0] }" (inViewportAction)="startCounter( counter )">
			<div *ngFor="let iVal of infoData.project_information; let i=index;" class="info-block__repeater">
				<div class="btn-circle" *ngIf="iVal.total != '' && iVal.text != ''">
					<h4 id="counter-{{ i }}">{{ iVal.total }}</h4>
					<p [innerHtml]="iVal.text"></p>
				</div>
			</div>
		</div>
	</div>
</section>
