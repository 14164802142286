import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import Masonry from 'masonry-layout';
import * as imagesLoaded from 'src/assets/js/imagesloaded.pkgd.min.js';

@Component({
	selector      : 'app-partner',
	templateUrl   : './partner.component.html',
	encapsulation : ViewEncapsulation.None
})

export class PartnerComponent implements OnInit {
	@Input() partnerData      : any;
	@Input() partnerBlockData : any;
	public isMobile           : boolean;

	constructor() {
		if ( devicePixelRatio > 1.5 && document.body.clientWidth < 767 )
            this.isMobile = true;
        else
            this.isMobile = false;
	}

	ngOnInit() {}

	ngAfterViewInit() {
		if ( false === this.isMobile )
			// setTimeout (
			// 	() => {
					this.addMasonry();
			// 	}, 300
			// );
	}

	onResize(event) {
		this.addMasonry();
	}

	addMasonry() {
		let partnerGrid = document.querySelectorAll( '.partner-grid' );
		if ( partnerGrid ) {
			for( let i = 0; i < partnerGrid.length; i++ ) {
				var newsElem = document.querySelector( '.grid-partner-' + i );
				var newsElemmsnry = new Masonry( newsElem, {
					itemSelector: '.masonry-item',
					columnWidth: '.grid-sizer-5',
					horizontalOrder: true,
					percentPosition: true,
				});
				imagesLoaded( newsElem ).on( 'progress', function() {
					newsElemmsnry.layout();
				});
			}
			
		}
	}

	fix_url( $url ) {
        if ( $url.includes( 'https://' ) || $url.includes( 'http://' ) ) {
        } else
            $url = 'https://' + $url;

        return $url;
    }
}