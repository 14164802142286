import { Component, ViewEncapsulation } from '@angular/core';

import { AccountServiceProvider } from '../providers/account-service/account-service';
import { Shareddata } from '../providers/shareddata';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	encapsulation: ViewEncapsulation.None,
	host: {
		'(document:click)': 'onClick($event)',
	},
	providers: [Shareddata]
})

export class AppComponent {
	title = 'SIWIWWW';

	// cookieLawEl: any;

	public footerLoaded: boolean = false;

	constructor(
		private accountService: AccountServiceProvider,
		public shareddata: Shareddata,
	) { }

	ngOnInit() {
		this.loadScripts();
	}

	loadScripts() {
		const dynamicScripts = [
			'assets/js/custom.js'
		];
		for (let i = 0; i < dynamicScripts.length; i++) {
			const node = document.createElement('script');
			node.src = dynamicScripts[i];
			node.type = 'text/javascript';
			node.async = false;
			document.getElementsByTagName('head')[0].appendChild(node);
		}
	}

	ngAfterContentChecked() {
		document.querySelector('body').classList.add('is-loaded');
		
		// setTimeout(() => {
		// 	this.footerLoaded = this.shareddata.checkLoaded();
		// }, 1000);
		if (document.getElementById('footer__top')) {
			setTimeout(() => {
				window.addEventListener("scroll", () => {
					document.getElementById('footer__top').classList.remove('footer__lazy')
				});
			}, 1100);
		}
	}

	onActivate(event) {
		let scrollToTop = window.setInterval(() => {
			let pos = window.pageYOffset;
			if (pos > 0) {
				window.scrollTo(0, pos - 20); // how far to scroll on each step
			} else {
				window.clearInterval(scrollToTop);
			}
		}, 1);
	}

	onClick(event) {
	}
}
