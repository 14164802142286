import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { Config } from '../app/config';

@Injectable()
export class Dotnetservice {
    private dotnetserviceUrl: string;
    private dotnetserviceUrlLocal: string;
    private dotnetserviceLoginUrl: string;
    private userData: any;
    private accessToken: any;
    private rand_time     : number;

    constructor( public http: HttpClient, private cfg: Config ) {
        this.dotnetserviceUrl = this.cfg._apiEndpoint || 'https://siwi.websearchpro.net/API/';
        this.dotnetserviceLoginUrl = this.cfg._apiSite || 'https://siwi.websearchpro.net/';
        this.rand_time = Math.floor(Math.random() * (900000 - 100000 + 1)) + 100000;
    }

    public getPartner() {
        return this.dotNetCallGet( '', 'GetPartner' );
    }

    public getConferences( params ) {
        return this.dotNetCallGet( '', 'GetThisYearConferences?season=' + params.season );
    }

    public getProgrammes( params ) {
        let paramsType = params.type;
        if ( null == params.type ) {
            paramsType = 'event';
        }
        var paramsData = '?skip=' + params.offset + '&type=' + paramsType;
        if ( 'exhibition' != paramsType ) {
            if ( 0 != params.year && undefined != params.year )
                paramsData += '&yearId=' + params.year;
            if ( 0 != params.tag_id && undefined != params.tag_id )
                paramsData += '&tags=' + params.tag_id;
            if ( 0 != params.weekday_id && undefined != params.weekday_id ) {
                var d = new Date();
                var n = d.getDay() - 1;
                if ( n != params.weekday_id )
                    paramsData += '&isConference=false';
                else
                    paramsData += '&isConference=true';
                paramsData += '&weekday=' + params.weekday_id;
            } else
                paramsData += '&isConference=' + params.is_conference;
            if ( 0 != params.session_id && undefined != params.session_id )
                paramsData += '&sessionType=' + params.session_id;
            if ( 0 != params.goldstandard && undefined != params.goldstandard )
                paramsData += '&isGoldStandard=true';
            if ( 0 != params.opensession && undefined != params.opensession )
                paramsData += '&isOpenSession=true';
        }
        if( 'undefined' !== typeof params.searchText && null !== params.searchText && '' !== params.searchText )
            paramsData += '&searchText=' + params.searchText + '&searchOption=' + params.searchOption;
        paramsData += '&t=' + params.offset;

        return this.dotNetCallGet( '', 'GetProgrammesWithDescriptionAppTest' + paramsData );
    }

    public getTimeZone() {
        return this.dotNetCallGet( '', 'GetTimeZones' );
    }

    public getCurrentDays( params ) {
        let paramsType = params.type;
        if ( null == params.type ) {
            paramsType = 'event';
        }
        if ( typeof params.is_conference != 'undefined' )
            var paramsData = '?type=' + paramsType + '&isConference=' + params.is_conference;
        else
            var paramsData = '?type=' + paramsType;
        var localSessionItems = JSON.parse( sessionStorage.getItem( 'filterProgrammeKeys' ) );
        if ( null != localSessionItems ) {
            var weekday_id   = localSessionItems.weekday_id;
            var weekday_name = localSessionItems.weekday_name;
            if ( true == localSessionItems.season ) {
                if ( '0' != weekday_id && undefined != weekday_id ) {
                    let current_day_name = this.getCurrentDayName();
                    if ( current_day_name != weekday_name )
                        paramsData += '&isConference=false';
                    else
                        paramsData += '&isConference=true';
                } else
                    paramsData += '&isConference=true';
            }
            if ( '' !== localSessionItems.timezone )
                paramsData += '&timezone=' + localSessionItems.timezone;
        }
        return this.dotNetCallGet( '', 'GetActiveDateListV2' + paramsData );
    }

    public getTagsSessions( params ) {
        if ( typeof params.offset == 'undefined' )
            params.offset = 0;
        if ( typeof params.type == 'undefined' )
            params.type = 'event';
        var paramsData = '?skip=' + params.offset + '&type=' + params.type;
        var localSessionItems = JSON.parse( sessionStorage.getItem( 'filterProgrammeKeys' ) );
        var weekday_id   = localSessionItems.weekday_id;
        var weekday_name = localSessionItems.weekday_name;
        if ( true == localSessionItems.season ) {
            if ( '0' != weekday_id && undefined != weekday_id ) {
                let current_day_name = this.getCurrentDayName();
                if ( current_day_name != weekday_name )
                    paramsData += '&isConference=false';
                else
                    paramsData += '&isConference=true';
            } else
                paramsData += '&isConference=true';
        }
        var session_id    = localSessionItems.session_id;
        var tag_id        = localSessionItems.filter_id;
        var goldstandard  = localSessionItems.goldstandard;
        var opensession   = localSessionItems.opensession;
        var year_id       = localSessionItems.year;
        var search_text   = localSessionItems.search_text;
        var search_option = localSessionItems.search_option;
        if( weekday_id != '0' && undefined != weekday_id )
            paramsData += '&weekday=' + weekday_id;
        if ( session_id != '0' && undefined != session_id )
            paramsData += '&sessionType=' + session_id;
        if ( tag_id != '0' && undefined != tag_id ) {
            if ( localSessionItems.tag_count > 1 ) {
                var tag_array = tag_id.split( ',' );
                tag_array.forEach( (item, index) => {
                    paramsData += '&tags=' + item;
                });                        
            } else
                paramsData += '&tags=' + tag_id;
        }
        if ( goldstandard != '0' && undefined != goldstandard )
            paramsData += '&isGoldStandard=true';
        if ( opensession != 0 && undefined != opensession )
            paramsData += '&isOpenSession=true';
        if ( year_id != '0' && undefined != year_id )
            paramsData += '&yearId=' + year_id;
        if ( search_text != '' && undefined != search_text )
            paramsData += '&searchText=' + search_text;
        if ( search_option != '' && undefined != search_option )
            paramsData += '&searchOption=' + search_option;
        if ( undefined != params.date )
            paramsData += '&date=' + params.date;
        return this.dotNetCallGet( '', 'GetProgrammesTagsAndSessions' + paramsData );
    }

    public getYears( params ) {
        if ( typeof params.offset == 'undefined' )
            params.offset = 0;
        if ( typeof params.type == 'undefined' )
            params.type = 'event';
        var paramsData = '?skip=' + params.offset + '&type=' + params.type;
        var localSessionItems = JSON.parse( sessionStorage.getItem( 'filterProgrammeKeys' ) );
        var weekday_id   = localSessionItems.weekday_id;
        var weekday_name = localSessionItems.weekday_name;
        if ( true == localSessionItems.season ) {
            if ( '0' != weekday_id && undefined != weekday_id ) {
                let current_day_name = this.getCurrentDayName();
                if ( current_day_name != weekday_name )
                    paramsData += '&isConference=false';
                else
                    paramsData += '&isConference=true';
            } else
                paramsData += '&isConference=true';
        }
        var session_id    = localSessionItems.session_id;
        var tag_id        = localSessionItems.filter_id;
        var goldstandard  = localSessionItems.goldstandard;
        var opensession   = localSessionItems.opensession;
        var year_id       = localSessionItems.year;
        var search_text   = localSessionItems.search_text;
        var search_option = localSessionItems.search_option;
        if( weekday_id != '0' && undefined != weekday_id )
            paramsData += '&weekday=' + weekday_id;
        if ( session_id != '0' && undefined != session_id )
            paramsData += '&sessionType=' + session_id;
        if ( tag_id != '0' && undefined != tag_id ) {
            if ( localSessionItems.tag_count > 1 ) {
                var tag_array = tag_id.split( ',' );
                tag_array.forEach( (item, index) => {
                    paramsData += '&tags=' + item;
                });                        
            } else
                paramsData += '&tags=' + tag_id;
        }
        if ( goldstandard != '0' && undefined != goldstandard )
            paramsData += '&isGoldStandard=true';
        if ( opensession != 0 && undefined != opensession )
            paramsData += '&isOpenSession=true';
        if ( year_id != '0' && undefined != year_id )
            paramsData += '&yearId=' + year_id;
        if ( search_text != '' && undefined != search_text )
            paramsData += '&searchText=' + search_text;
        if ( search_option != '' && undefined != search_option )
            paramsData += '&searchOption=' + search_option;
        return this.dotNetCallGet( '', 'GetYearList' + paramsData );
    }

    public getProgrammeDateTime() {
        var paramsData = '?skip=0';
        let localSessionItems = JSON.parse(sessionStorage.getItem('filterProgrammeKeys'));
        if ( localSessionItems && '' !== localSessionItems.timezone )
            paramsData += '&timezone=' + localSessionItems.timezone;
        return this.dotNetCallGet( '', 'GetProgrammesDateAndTimeRangeV2' + paramsData );
    }

    public getProgrammesByDateTime( params ) {
        var paramsData = '?type=event';
        if ( undefined != params.time )
            paramsData += '&interval=' + params.time;
        if ( undefined != params.is_conference && params.is_conference == true )
            paramsData += '&isConference=true';
        else
            paramsData += '&date=' + params.date;
        let localSessionItems = JSON.parse(sessionStorage.getItem('filterProgrammeKeys'));
        if ( localSessionItems && '' !== localSessionItems.timezone )
            paramsData += '&timezone=' + localSessionItems.timezone;        
        return this.dotNetCallGet( '', 'GetProgrammesWithDescriptionMobile' + paramsData );
    }

    public getProgrameDetail( params ) {
        return this.dotNetCallGet( '', 'GetProgrammeDetail?id=' + params.id + '&timezone=' + params.timezone );
    }

    public getProgrameTagsSession( params ) {
        var paramsData = '?id=' + params.id + '&isConference=' + params.is_conference
        return this.dotNetCallGet( '', 'GetProgrammeDetailTags' + paramsData );
    }

    public getProgrammesByFilter( params ) {
        let paramsType = params.type;
        if ( null == paramsType ) {
            paramsType = 'event';
        }
        var paramsData = '?skip=' + params.offset + '&type=' + paramsType;
        var localSessionItems = JSON.parse( sessionStorage.getItem( 'filterProgrammeKeys' ) );
        var weekday_id   = localSessionItems.weekday_id;
        var weekday_name = localSessionItems.weekday_name;
        if ( true == localSessionItems.season ) {
            if ( '0' != weekday_id && undefined != weekday_id ) {
                let current_day_name = this.getCurrentDayName();
                if ( current_day_name != weekday_name )
                    paramsData += '&isConference=false';
                else
                    paramsData += '&isConference=true';
            } else
                paramsData += '&isConference=true';
        } else
            paramsData += '&isConference=false';
        var session_id    = localSessionItems.session_id;
        var tag_id        = localSessionItems.filter_id;
        var goldstandard  = localSessionItems.goldstandard;
        var opensession   = localSessionItems.opensession;
        var year_id       = localSessionItems.year;
        var search_text   = localSessionItems.search_text;
        var search_option = localSessionItems.search_option;
        var time_zone     = localSessionItems.timezone;
        if( weekday_id != '0' && undefined != weekday_id )
            paramsData += '&date=' + weekday_id;
        if ( session_id != '0' && undefined != session_id )
            paramsData += '&sessionType=' + session_id;
        if ( tag_id != '0' && undefined != tag_id ) {
            if ( localSessionItems.tag_count > 1 ) {
                var tag_array = tag_id.split( ',' );
                tag_array.forEach( (item, index) => {
                    paramsData += '&tags=' + item;
                });                        
            } else
                paramsData += '&tags=' + tag_id;
        }
        if ( goldstandard != '0' && undefined != goldstandard )
            paramsData += '&isGoldStandard=true';
        if ( opensession != 0 && undefined != opensession )
            paramsData += '&isOpenSession=true';
        if ( year_id != '0' && undefined != year_id )
            paramsData += '&yearId=' + year_id;
        if ( search_text != '' && undefined != search_text )
            paramsData += '&searchText=' + search_text;
        if ( search_option != '' && undefined != search_option )
            paramsData += '&searchOption=' + search_option;
        if ( localSessionItems.date && localSessionItems.date != '' )
            paramsData += '&date=' + localSessionItems.date;
        if ( '' != time_zone && undefined != time_zone )
            paramsData += '&timezone=' + time_zone;
        return this.dotNetCallGet( '', 'GetProgrammesWithDescriptionApp' + paramsData );
    }

    public getProgrammesByFilterMobile( params ) {
        let paramsType = params.type;
        if ( null == paramsType ) {
            paramsType = 'event';
        }
        var paramsData = '?skip=' + params.offset + '&type=' + paramsType;
        var localSessionItems = JSON.parse( sessionStorage.getItem( 'filterProgrammeKeys' ) );
        var weekday_id   = localSessionItems.weekday_id;
        var weekday_name = localSessionItems.weekday_name;
        if ( true == localSessionItems.season ) {
            if ( '0' != weekday_id && undefined != weekday_id ) {
                let current_day_name = this.getCurrentDayName();
                if ( current_day_name != weekday_name )
                    paramsData += '&isConference=false';
                else
                    paramsData += '&isConference=true';
            } else {
                paramsData += '&isConference=true';
            }
        } else
            paramsData += '&isConference=false';
        var session_id    = localSessionItems.session_id;
        var tag_id        = localSessionItems.filter_id;
        var goldstandard  = localSessionItems.goldstandard;
        var opensession   = localSessionItems.opensession;
        var year_id       = localSessionItems.year;
        var search_text   = localSessionItems.search_text;
        var search_option = localSessionItems.search_option;
        var time_zone     = localSessionItems.timezone;
        if( weekday_id != '0' && undefined != weekday_id )
            paramsData += '&weekday=' + weekday_id;
        if ( session_id != '0' && undefined != session_id )
            paramsData += '&sessionType=' + session_id;
        if ( tag_id != '0' && undefined != tag_id ) {
            if ( localSessionItems.tag_count > 1 ) {
                var tag_array = tag_id.split( ',' );
                tag_array.forEach( (item, index) => {
                    paramsData += '&tags=' + item;
                });                        
            } else
                paramsData += '&tags=' + tag_id;
        }
        if ( goldstandard != '0' && undefined != goldstandard )
            paramsData += '&isGoldStandard=true';
        if ( opensession != 0 && undefined != opensession )
            paramsData += '&isOpenSession=true';
        if ( year_id != '0' && undefined != year_id )
            paramsData += '&yearId=' + year_id;
        if ( search_text != '' && undefined != search_text )
            paramsData += '&searchText=' + search_text;
        if ( search_option != '' && undefined != search_option )
            paramsData += '&searchOption=' + search_option;
        if ( localSessionItems.date && localSessionItems.date != '' )
            paramsData += '&date=' + localSessionItems.date;
        if ( '' != time_zone && undefined != time_zone )
            paramsData += '&timezone=' + time_zone;
        return this.dotNetCallGet( '', 'GetProgrammesWithDescriptionMobile' + paramsData );
    }

    public getCurrentDayName() {
        var d = new Date();
        var weekday = new Array( 6 );
        weekday[0] = "SUNDAY";
        weekday[1] = "MONDAY";
        weekday[2] = "TUESDAY";
        weekday[3] = "WEDNESDAY";
        weekday[4] = "THURSDAY";
        weekday[5] = "FRIDAY";
        var n = weekday[d.getDay()];
        return n;
    }

    public getProgrammesByFilterSearch( params ) {
        let paramsType = params.type;
        if ( null == paramsType ) {
            paramsType = 'event';
        }
        var paramsData = '?skip=' + params.offset + '&type=' + paramsType;
        if( 'undefined' !== typeof params.searchText && null !== params.searchText && '' !== params.searchText )
            paramsData += '&searchText=' + params.searchText + '&searchOption=' + params.searchOption;        
        if ( 'filterSearchProgrammeKeys' in window.sessionStorage ) {
            var localSessionItems = JSON.parse( sessionStorage.getItem( 'filterSearchProgrammeKeys' ) );
            var session_id   = localSessionItems.session_id;
            var tag_id       = localSessionItems.filter_id;
            var goldstandard = localSessionItems.goldstandard;
            var opensession  = localSessionItems.opensession;            
            if ( session_id != '0' )
                paramsData += '&sessionType=' + session_id;
            if ( tag_id != '0' ) {
                if ( localSessionItems.tag_count > 1 ) {
                    var tag_array = tag_id.split( ',' );
                    tag_array.forEach( (item, index) => {
                        paramsData += '&tags=' + item;
                    });
                } else
                    paramsData += '&tags=' + tag_id;
            }
            if ( goldstandard != '0' )
                paramsData += '&isGoldStandard=true';
            if ( opensession != 0 )
                paramsData += '&isOpenSession=true';
        }
        return this.dotNetCallGet( '', 'GetProgrammesWithDescription' + paramsData );
    }

    public getTagName( params ) {
        return this.dotNetCallGet( '', 'GetTagName?id=' + params.tag_id );
    }

    public getOrganisations( params ) {
        var paramsData = '?skip=' + params.offset;
        if ( 'filterOrganistions' in window.sessionStorage ) {
            var localOrgSessionItems = JSON.parse( sessionStorage.getItem( 'filterOrganistions' ) );
            var continent_id = localOrgSessionItems.continent_id;
            var org_type_id  = localOrgSessionItems.org_type_id;
            var tag_id       = localOrgSessionItems.filter_id;
            var search_text  = localOrgSessionItems.search_text;
            if ( continent_id != 0 )
                paramsData += '&continent=' + continent_id;
            if ( org_type_id != 0 )
                 paramsData += '&type=' + org_type_id;
            if ( tag_id != 0 ) {
                if ( localOrgSessionItems.tag_count > 1 ) {
                    var tag_array = tag_id.split( ',' );
                    tag_array.forEach( ( item, index ) => {
                        paramsData += '&tags=' + item;
                    });
                } else
                    paramsData += '&tags=' + tag_id;
            }
            if ( search_text )
                paramsData += '&searchText=' + search_text;
        } else {
            if( 'undefined' !== typeof params.searchText && null !== params.searchText && '' !== params.searchText )
                paramsData += '&searchText=' + params.searchText;
            if ( 'undefined' !== typeof params.detail_filter_id && null !== params.detail_filter_id && '' !== params.detail_filter_id )
                paramsData += '&tags=' + params.detail_filter_id;
        }
        if ( 'media' == params.org_type )
            paramsData += '&ismedia=true';
        else if( 'partner' == params.org_type )
            paramsData += '&isPartner=true';        
        return this.dotNetCallGet( '', 'GetFilteredOrganizationList' + paramsData );
    }

    public getConveningOrganisations( params ) {
        var paramsData = '?skip=' + params.offset;
        if ( 'filterConveningOrganistions' in window.sessionStorage ) {
            var localOrgSessionItems = JSON.parse( sessionStorage.getItem( 'filterConveningOrganistions' ) );
            var continent_id = localOrgSessionItems.continent_id;
            var org_type_id  = localOrgSessionItems.org_type_id;
            var tag_id       = localOrgSessionItems.filter_id;
            var search_text  = localOrgSessionItems.search_text;
            if ( continent_id != 0 )
                paramsData += '&continent=' + continent_id;
            if ( org_type_id != 0 )
                 paramsData += '&type=' + org_type_id;
            if ( tag_id != 0 ) {
                if ( localOrgSessionItems.tag_count > 1 ) {
                    var tag_array = tag_id.split( ',' );
                    tag_array.forEach( ( item, index ) => {
                        paramsData += '&tags=' + item;
                    });
                } else
                    paramsData += '&tags=' + tag_id;
            }
            if ( search_text )
                paramsData += '&searchText=' + search_text;
        }
        return this.dotNetCallGet( '', 'GetConveningOrganizationList' + paramsData );
    }

    public getSpeakers( params ) {
        var paramsData = '?skip=' + params.offset;
        if ( 'filterSpeakers' in window.sessionStorage ) {
            var localSpeakerSessionItems = JSON.parse( sessionStorage.getItem( 'filterSpeakers' ) );
            var continent_id    = localSpeakerSessionItems.continent_id;
            var gender_id       = localSpeakerSessionItems.gender_id;
            var tag_id          = localSpeakerSessionItems.filter_id;
            var interview_id    = localSpeakerSessionItems.interview_id;
            var job_id          = localSpeakerSessionItems.job_id;
            var job_title       = localSpeakerSessionItems.job_title;
            var meeting_id      = localSpeakerSessionItems.meeting_id;
            var org_type_id     = localSpeakerSessionItems.org_type_id;
            var professional_id = localSpeakerSessionItems.professional_id;
            var speaker_id      = localSpeakerSessionItems.speaker_id;
            if ( 0 != continent_id )
                paramsData += '&continent=' + continent_id;
            if ( 0 != gender_id )
                paramsData += '&gender=' + gender_id;
            if ( 0 != interview_id )
                paramsData += '&opentointerview=' + interview_id;
            if ( '' != job_title )
                paramsData += '&jobtitle=' + job_title;
            if ( 0 != meeting_id )
                paramsData += '&opentomeetingrequest=' + meeting_id;
            if ( 0 != org_type_id )
                paramsData += '&type=' + org_type_id;
            if ( 0 != professional_id )
                paramsData += '&youngprofessional=' + professional_id;
            if ( 0 != speaker_id )
                paramsData += '&availablespeaker=' + speaker_id;
            if ( 0 != tag_id ) {
                if ( localSpeakerSessionItems.tag_count > 1 ) {
                    var tag_array = tag_id.split( ',' );
                    tag_array.forEach( ( item, index ) => {
                        paramsData += '&tags=' + item;
                    });
                } else
                    paramsData += '&tags=' + tag_id;
            }
        }
        return this.dotNetCallGet( '', 'GetSpeakers' + paramsData );
    }

    public organisationDetail( params ) {
        return this.dotNetCallGet( '', 'GetOrganizationDetail?organizationId=' + params.id );
    }

    public participantDetail( params ) {
        return this.dotNetCallGet( '', 'GetParticipantsDetails?UserId=' + params.id );
    }

    public getParticipants( params ) {
        var paramsData = '?skip=' + params.offset;
        return this.dotNetCallGet( '', 'GetParticipantsList' + paramsData );
    }

    public getFilteredParticipants( params )  {
        var paramsData = '?skip=' + params.offset;
        if ( 'filterParticipants' in window.sessionStorage ) {
            var localParticipanSessionItems = JSON.parse( sessionStorage.getItem( 'filterParticipants' ) );
            var continent_id = localParticipanSessionItems.continent_id;
            var gender_id  = localParticipanSessionItems.gender_id;
            var tag_id       = localParticipanSessionItems.filter_id;
            var interview_id = localParticipanSessionItems.interview_id;
            var job_id  = localParticipanSessionItems.job_id;
            var meeting_id       = localParticipanSessionItems.meeting_id;
            var org_type_id = localParticipanSessionItems.org_type_id;
            var professional_id  = localParticipanSessionItems.professional_id;
            var speaker_id       = localParticipanSessionItems.speaker_id;
            var search_text = localParticipanSessionItems.search_text;
            if ( 0 != continent_id )
                paramsData += '&continent=' + continent_id;
            if ( 0 != gender_id )
                paramsData += '&gender=' + gender_id;
            if ( 0 != interview_id )
                paramsData += '&opentointerview=' + interview_id;
            if ( 0 != job_id )
                paramsData += '&jobtitle=' + job_id;
            if ( 0 != meeting_id )
                paramsData += '&opentomeetingrequest=' + meeting_id;
            if ( 0 != org_type_id )
                paramsData += '&type=' + org_type_id;
            if ( 0 != professional_id )
                paramsData += '&youngprofessional=' + professional_id;
            if ( 0 != speaker_id )
                paramsData += '&availablespeaker=' + speaker_id;
            if ( 0 != tag_id ) {
                if ( localParticipanSessionItems.tag_count > 1 ) {
                    var tag_array = tag_id.split( ',' );
                    tag_array.forEach( ( item, index ) => {
                        paramsData += '&tags=' + item;
                    });
                } else
                    paramsData += '&tags=' + tag_id;
            }
            if ( search_text )
                paramsData += '&searchText=' + search_text;
        }
        return this.dotNetCallGet( '', 'GetFilteredParticipantsList' + paramsData );
    }

    public getProgrammeTypes() {
        return this.dotNetCallGet( '', 'GetProgrammeTypes' );
    }

    public getProgrameTypeDetail( params ) {
        return this.dotNetCallGet( '', 'GetProgrammeTypeDetail?id=' + params.id );
    }

    public getPressEvents() {
        return this.dotNetCallGet( '', 'GetPressEvents' );
    }

    public getOpenForInterviewParticipants( params ) {
        return this.dotNetCallGet( '', 'OpenForInterviewParticipantsList?skip=' + params.offset );
    }

    public getConvenorPressRelease( params ) {
        return this.dotNetCallGet( '', 'ConvenorPress/GetLatest?skip=0'/* + params.offset + '&take=10'*/ );
    }

    public addConvenorPress( params ) {
        return this.webCall( params, 'ConvenorPress/Add' );
    }

    public sendMessage( params ) {
        return this.webCall( params, 'SendEmail' );
    }

    public getSearchResult( params ) {
        return this.dotNetCallGet( '', 'GetSearchResult/?searchOption=' + params.searchOption + '&searchText=' + params.searchText + '&skip=' + params.offset );
    }

    public getPrevYears() {
        return this.dotNetCallGet( '', 'GetWwwYears' );
    }

    public getLiveMedia() {
        return this.dotNetCallGet( '', 'GetLiveMedia' );
    }

    public getFeeds( params ) {
        return this.dotNetCallGet( '', 'Newsfeed/GetNewsfeeds?skip='+params.offset );
    }

    public saveFeed( params ) {
        return this.webCall( params, 'Newsfeed/Save' );
    }

    public saveComment( params ) {
        return this.webCall( params, 'NewsFeed/SaveComment' );
    }

    public saveLike( params ) {
        if ( params.likedByMe == false )
            return this.webCall( params, 'Newsfeed/AddLike' );
        else
            return this.webCall( params, 'Newsfeed/RemoveLike' );
    }

    public deleteFeed( params ) {
        return this.webCall( params, 'Newsfeed/Delete' );
    }

    public sendEmail( params ) {
        return this.webCall( '', 'Favourite/sendemail?EmailTo=' + params.EmailTo );
    }

    public doUserLogin( params ) {
        return this.webCallLogin( params, 'authenticate' );
    }

    public webCallCookie( params, method ) {
        let options;
        if ( 'userData' in window.localStorage ) {
            this.userData = JSON.parse( window.localStorage.getItem( 'userData' ) );
            this.accessToken = this.userData.access_token;
            options = {
                headers: new HttpHeaders({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                    'Authorization': 'Bearer ' + this.accessToken,
                })
            }
        } else {
            options = {
                headers: new HttpHeaders({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                })
            } 
        }
        return this.http.get( this.dotnetserviceLoginUrl + method, options ).map( res => res );
    }

    public forgotPassword( params ) {
        return this.webCallUser( params, 'ForgotPassword' );
    }

    public resetPassword( params ) {
        return this.webCallUser( params, 'ResetPassword' );
    }

    public verifyForgotPassword( params ) {
        return this.webCallVerify( params, 'VerifyForgotPassword' );
    }

    public getCountry() {
        return this.dotNetCallGet( '', 'GetCountry' );
    }

    public doUserSignUp( params ) {
        return this.webCall( params, 'SignUpUserNew?FirstName=' + params.FirstName + '&LastName=' + params.LastName + '&Email=' + params.Email + '&CountryId=' + params.CountryId );
    }

    public webCallUser( params, method ) {
       let options = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
            })
        }
        return this.http.post( this.dotnetserviceUrl + method, JSON.stringify( params ), options ).map( res => res );
    }

    public webCallVerify( params, method ) {
        let options = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
            })
        }
        let body = '?code=' + params.Code;
        return this.http.get( this.dotnetserviceUrl + method + body, options ).map( res => res );
    }

    public webCallLogin( params, method ) {
        let options = {
            headers: new HttpHeaders({
                'Accept': 'application/JSONP',
                // 'Content-Type': 'application/JSONP',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin':'*'
            }),
            responseType: 'text' as 'json'
        }
        let body = 'username=' + params.Username + '&password=' + params.Password + '&grant_type=password';
        return this.http.post( this.dotnetserviceLoginUrl + method, body, options ).map( res => res );
    }

    public webCall( params, method ) {
        let options;
        if ( 'userData' in window.localStorage ) {
            this.userData = JSON.parse( window.localStorage.getItem( 'userData' ) );
            this.accessToken = this.userData.access_token;
            options = {
                headers: new HttpHeaders({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                    'Authorization': 'Bearer ' + this.accessToken,
                })
            }
        } else {
           options = {
                headers: new HttpHeaders({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                })
            } 
        }
        return this.http.post( this.dotnetserviceUrl + method, JSON.stringify( params ), options ).map( res => res );
    }

    public dotNetCallGet( params, method ) {
        let options;
       let headers = new HttpHeaders();
        headers.append( 'Accept', 'application/json' );
        headers.append( 'Content-Type', 'application/x-www-form-urlencoded' );
        if ( 'userData' in window.localStorage ) {
            this.userData = JSON.parse( window.localStorage.getItem( 'userData' ) );
            this.accessToken = this.userData.access_token;
            options = {
                headers: new HttpHeaders({
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + this.accessToken,
                })
            }
        } else {
            options = {
                headers: new HttpHeaders({
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                })
            }
        }        
        if ( method.includes( '?' ) ) {
            // return this.http.get( this.dotnetserviceUrl + method + '&t=' + this.rand_time, options ).map( res => res );
            return this.http.get( this.dotnetserviceUrl + method, options ).map( res => res );
        } else {
            // return this.http.get( this.dotnetserviceUrl + method + '?t=' + this.rand_time, options ).map( res => res );
            return this.http.get( this.dotnetserviceUrl + method, options ).map( res => res );
        }
    }
}