<section id="venue" class="component component--venue venue text-color-dark"> 
	<div class="venue__image">
		<img src="{{venueData?.venue_images.url}}" loading="lazy" width="1903" height="433"/>
	</div>
	<div class="wrapper">
		<div class="venue__text">
			<div class="block-tag" *ngIf="venueData?.venue_block_title != ''">
				<b class="tag-head"></b>
				<span [innerHtml]="venueData?.venue_block_title"></span>
			</div>
			<div class="venue__content">
				<h2 [innerHtml]="venueData?.venue_title"></h2>
				<div class="content" [innerHtml]="venueData?.content | safe:'preamble'"></div>
				<div class="link-wrapper" *ngIf="venueData?.venue_links != ''">
					<span class="link" *ngFor="let vlVal of venueData?.venue_links">
						<a class="btn btn-block btn-circle" href="{{ vlVal?.venue_link?.url }}" target="{{ vlVal?.venue_link?.target }}">{{ vlVal?.venue_link?.title }} <span><i class="icon-arrow-right"></i></span></a>
					</span>
				</div>
			</div>
		</div>
	</div>
	<span class="venue__image-caption" *ngIf="venueData.venue_images.caption != ''" [innerHtml]="venueData.venue_images.caption | safe:'preamble'"></span>
</section>