import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
	selector: 'app-home-wp-engage',
	templateUrl: './home-wp-engage.component.html',
	encapsulation : ViewEncapsulation.None,
})
export class HomeWpEngageComponent implements OnInit {
	@Input() wpEngageContent : any;	
	constructor() {}
	ngOnInit(): void {}
}