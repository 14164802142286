import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Webservice } from '../webservice';
import { Dotnetservice } from '../dotnetservice';

/*
  Generated class for the AccountServiceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class AccountServiceProvider {

	constructor(
		private webservice    : Webservice,
		private dotnetservice : Dotnetservice
		) {
	}
	/* WP Apis */
	getHeaderFooterMenu( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getHeaderFooterMenu( params ).subscribe( response => {
			 		resolve( response );
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getMenusFooter() {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getMenusFooter().subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getNewHeader() {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getNewHeader().subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getSearchDropDownData( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getSearchDropDownData( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getStartPageData() {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getStartPageData().subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}	

	getKeynote( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getKeynote( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}	

	doUserLogin( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.doUserLogin( params ).subscribe( response => {
				 	resolve( response );
			 },
			 err => {
				let response: any;
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': 3111
			 	};

			 	resolve( response );			 	
			 })
		});
	}

	verifyForgotPassword( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.verifyForgotPassword( params ).subscribe( response => {
			 		resolve( response );
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 })
		});
	}
	
	resetPassword( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.resetPassword( params ).subscribe( response => {
			 		resolve( response );
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 })
		});
	}

	getLoginContent() {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getLoginContent().subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getInnerPageData( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getInnerPageData( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getProgrammePageData( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getProgrammePageData( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getMoreNews( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getMoreNews( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getSearchData( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getSearchData( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getSearchOptions( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getSearchOptions( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getBreadCrumb( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getBreadCrumb( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getCommunityTabContent( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getCommunityTabContent( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getNewsDetail( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getNewsDetail( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getOrgTagType( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getOrgTagType( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getTabData( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getTabData( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getNotFoundPage() {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getNotFoundPage().subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getTeamInfo() {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getTeamInfo().subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getTeamDetail( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getTeamDetail( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getCurrentSiwiSeason() {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getCurrentSiwiSeason().subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getWpTeamParticipants( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.webservice.getWpTeamParticipants( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}
	/* Dotnet Apis */
	getPartner() {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getPartner().subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getConferences( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getConferences( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}	

	getPrevYears() {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getPrevYears().subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}	

	getCountry() {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getCountry().subscribe( response => {
			 		resolve( response );
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 })
		});
	}

	doUserSignUp( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.doUserSignUp( params ).subscribe( response => {
			 		resolve( response );
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 })
		});
	}

	getProgrammes( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getProgrammes( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getTimeZone() {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getTimeZone().subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getCurrentDays( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getCurrentDays( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getTagsSessions( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getTagsSessions( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getYears( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getYears( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getProgrammeDateTime() {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getProgrammeDateTime().subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getProgrammesByDateTime( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getProgrammesByDateTime( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getProgrameDetail( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getProgrameDetail( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getProgrameTagsSession( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getProgrameTagsSession( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getTagName( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getTagName( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getProgrammeTypes() {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getProgrammeTypes().subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getProgrameTypeDetail( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getProgrameTypeDetail( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getProgrammesByFilter( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getProgrammesByFilter( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getProgrammesByFilterMobile( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getProgrammesByFilterMobile( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getProgrammesByFilterSearch( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getProgrammesByFilterSearch( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getPressEvents() {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getPressEvents().subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getOpenForInterviewParticipants( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getOpenForInterviewParticipants( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getOrganisations( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getOrganisations( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getConveningOrganisations( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getConveningOrganisations( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	organisationDetail( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.organisationDetail( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}	

	getSpeakers( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getSpeakers( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getParticipants( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getParticipants( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getFilteredParticipants( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getFilteredParticipants( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	participantDetail( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.participantDetail( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getConvenorPressRelease( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getConvenorPressRelease( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	addConvenorPress( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.addConvenorPress( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	sendMessage( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.sendMessage( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getSearchResult( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getSearchResult( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getLiveMedia() {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getLiveMedia().subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	getFeeds( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.getFeeds( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	saveFeed( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.saveFeed( params ).subscribe( response => {
			 		resolve( response );
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 })
		});
	}	

	saveComment( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.saveComment( params ).subscribe( response => {
			 		resolve( response );
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 })
		});
	}

	saveLike( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.saveLike( params ).subscribe( response => {
			 		resolve( response );
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 })
		});
	}	

	deleteFeed( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.deleteFeed( params ).subscribe( response => {
			 		resolve( response );
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 })
		});
	}

	sendEmail( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.sendEmail( params ).subscribe( response => {
			 	resolve( response )
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 });
		});
	}

	forgotPassword( params ) {
		return new Promise<any>( ( resolve, reject ) => {
			 this.dotnetservice.forgotPassword( params ).subscribe( response => {
			 		resolve( response );
			 },
			 err => { 	
			 	let response: any;
			 	
			 	response = {
			 		'status': 'ERROR',
			 		'errcode': '3111'
			 	};

			 	resolve( response );			 	
			 })
		});
	}
}
