import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
	selector      : 'app-venue',
	templateUrl   : './venue.component.html',
	encapsulation : ViewEncapsulation.None
})

export class VenueComponent implements OnInit {
	@Input() venueData : any;

	constructor() {}

	ngOnInit() {}
}