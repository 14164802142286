<section class="component component--engage-block engage-block" [ngStyle]="{'background-color': wpEngageContent.wp_engage_block_background_color}">
    <div class="wrapper">
		<div class="row">
			<div class="col-sm-6 engage-col-left">
				<div class="engage-left">
					<div class="engage-left-content">
                        <h2 *ngIf="'' != wpEngageContent.wp_engage_block_title" [innerHtml]="wpEngageContent.wp_engage_block_title"></h2>
						<p *ngIf="'' != wpEngageContent.wp_engage_block_content" [innerHtml]="wpEngageContent.wp_engage_block_content"></p>
                        <a *ngIf="'internal' == wpEngageContent.wp_engage_block_link_type" routerLink="{{ wpEngageContent.wp_engage_block_link_internal }}" class="btn btn-primary btn_small btn-witharrow"><span class="btn-label" [innerHtml]="wpEngageContent.wp_engage_block_link_type_title"></span></a>
                        <a *ngIf="'external' == wpEngageContent.wp_engage_block_link_type" href="{{ wpEngageContent.wp_engage_block_link }}" target="_blank" class="btn btn-primary btn_small btn-witharrow"><span class="btn-label" [innerHtml]="wpEngageContent.wp_engage_block_link_type_title"></span></a>
                        <a *ngIf="'mail' == wpEngageContent.wp_engage_block_link_type" href="mailto:{{ wpEngageContent.wp_engage_block_email }}" class="btn btn-primary btn_small btn-witharrow"><span class="btn-label" [innerHtml]="wpEngageContent.wp_engage_block_link_type_title"></span></a>
					</div>
				</div>
			</div>
			<div class="col-sm-6 engage-col-right">
				<div class="engage-right" [ngStyle]="{'background-image': 'url(' + wpEngageContent.wp_engage_upload_image + ')'}">
                    <div class="engage-right-content" *ngIf="'' != wpEngageContent.eb_choose_members">
                        <div class="engage-withfigure d-flex bck-style-white" *ngFor="let ecmVal of wpEngageContent.eb_choose_members">
                            <div class="svgcurve svgcurve1"></div>
								<div class="bck-style-inner">
									<a routerLink="{{ ecmVal.link }}">
                                        <figure class="image-center por">
                                            <img src="{{ ecmVal.image }}" class="img-responsive" alt="{{ ecmVal.title }}">
                                        </figure>
									</a>
									<div class="engage-figuretext">
										<a routerLink="{{ ecmVal.link }}">
											<div class="author-name customer_name" *ngIf="'' != ecmVal.title" [innerHtml]="ecmVal.title"></div>
											<div class="author-position customer_position" *ngIf="'' != ecmVal.position" [innerHtml]="ecmVal.position"></div>
											<div class="author_organisation customer_dpt"></div>
										</a>
										<div class="engage-author" *ngIf="'' != ecmVal.email || '' != ecmVal.phone">
											<div class="engage-author-phone" *ngIf="'' != ecmVal.phone"><span class="icon icon-telephone"></span><span class="author-phone">{{ ecmVal.phone }}</span></div>
											<div class="engage-author-email" *ngIf="'' != ecmVal.email"><a href="mailto:{{ ecmVal.email }}"><span class="icon_mail icon-landline"></span><span class="author-email">{{ ecmVal.email }}</span></a></div>
										</div>
									</div>
                                </div>
                            </div>
						</div>
				</div>
			</div>
		</div>
	</div>
</section>