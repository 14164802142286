import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
	selector      : 'app-home-fact',
	templateUrl   : './home-fact.component.html',
	encapsulation : ViewEncapsulation.None,
})
export class HomeFactComponent implements OnInit {
	@Input() factContent : any;
	constructor() {}
	ngOnInit() {}
}