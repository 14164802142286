import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import Masonry from 'masonry-layout';
import * as imagesLoaded from 'src/assets/js/imagesloaded.pkgd.min.js';

@Component({
	selector      : 'app-news-home',
	templateUrl   : './news-home.component.html',
	encapsulation : ViewEncapsulation.None,
})
export class NewsHomeComponent implements OnInit {
	@Input() homeNewsContent : any;

	constructor() { }

	ngOnInit(): void {}	

	showHidden( e ) {
		e.target.nextElementSibling.classList.remove( 'hide' );
		e.target.classList.add( 'hide' );
	}

	ngAfterViewInit() {
		setTimeout (
			() => {
				if ( document.querySelector( '.no-big-news .grid-news' ) ) {
					var teamElem = document.querySelector( '.no-big-news .grid-news' );
					var teamElemmsnry = new Masonry( teamElem, {
						itemSelector: '.grid-item-news',
						columnWidth: '.grid-sizer',
						horizontalOrder: true,
						percentPosition: true,
					});

					imagesLoaded( teamElem ).on( 'progress', function() {
						teamElemmsnry.layout();
					});
				}
			}, 500
		);

		setTimeout (
			() => {
				if ( document.querySelector( '.cover-first-row .grid-news' ) ) {
					var teamElem = document.querySelector( '.cover-first-row .grid-news' );
					var teamElemmsnry = new Masonry( teamElem, {
						itemSelector: '.grid-item-news',
						columnWidth: '.grid-sizer',
						horizontalOrder: true,
						percentPosition: true,
					});

					imagesLoaded( teamElem ).on( 'progress', function() {
						teamElemmsnry.layout();
					});
				}
			}, 500
		);

		setTimeout (
			() => {
				if ( document.querySelector( '.cover-second-column .grid-news' ) ) {
					var teamElem = document.querySelector( '.cover-second-column .grid-news' );
					var teamElemmsnry = new Masonry( teamElem, {
						itemSelector: '.grid-item-news',						
						stamp: '.stamp',
						columnWidth: '.grid-sizer',
						horizontalOrder: true,
						percentPosition: true,
					});

					imagesLoaded( teamElem ).on( 'progress', function() {
						teamElemmsnry.layout();
					});
				}
			}, 500
		);
	}

}