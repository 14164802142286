import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import Masonry from 'masonry-layout';
import * as imagesLoaded from 'src/assets/js/imagesloaded.pkgd.min.js';

@Component({
	selector      : 'app-accordion',
	templateUrl   : './accordion.component.html',
	encapsulation : ViewEncapsulation.None,
})
export class AccordionComponent implements OnInit {
	@Input() accordionContent : any;
	constructor() {}
	ngOnInit(): void {}

	accordion( event : any, classname : string ) {
		const target   = event.target;
		const hasClass = target.parentElement.classList.contains( classname );
		if( hasClass )
			target.parentElement.classList.remove( classname );
		else {
			var elems = document.querySelectorAll( '.' + classname );
			[].forEach.call( elems, function( el ) {
				el.classList.remove( classname );
			});
			target.parentElement.classList.add( classname );
		}
		setTimeout( () => {
			const scrollElement = document.querySelector( '.' + classname );
			const yCoordinate = scrollElement.getBoundingClientRect().top + window.pageYOffset;
			let yOffset;

			yOffset = -60;
			window.scrollTo({
				top: yCoordinate + yOffset,
				behavior: 'smooth'
			});
		}, 1 );
	}
}
