import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule , ReactiveFormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SafePipe } from '../pipes/pipes';
import { TruncateTextPipe } from '../pipes/truncatetext';

@NgModule({
	declarations: [
		SafePipe,
		TruncateTextPipe,
	],
	exports: [
		SafePipe,
		TruncateTextPipe,
		RouterModule,
	],
	imports: [
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		RouterModule,		
	],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA,
		NO_ERRORS_SCHEMA,
	],
})

export class SharingModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: SharingModule,
		};
	}
}