import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
@Component({
	selector      : 'app-home-file',
	templateUrl   : './home-file.component.html',
	encapsulation : ViewEncapsulation.None,
})
export class HomeFileComponent implements OnInit {
	@Input() fileContent : any;
	constructor() {}
	ngOnInit(): void {}
}