import { Component, OnInit, ViewEncapsulation, Input, ViewChild  } from '@angular/core';
import { AccountServiceProvider } from '../../providers/account-service/account-service';
import { Shareddata } from '../../providers/shareddata';
import Masonry from 'masonry-layout';
import * as imagesLoaded from 'src/assets/js/imagesloaded.pkgd.min.js';
import smoothscroll from 'smoothscroll-polyfill';
import { NgxMasonryComponent, NgxMasonryOptions } from 'ngx-masonry';

@Component({
	selector      : 'app-conference',
	templateUrl   : './conference.component.html',
	encapsulation : ViewEncapsulation.None
})

export class ConferenceComponent implements OnInit {
	@ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;
	@Input() conferenceWpData     : any;
	@Input() conferenceDotNetData : any;
	@Input() conferenceSeason     : any;
	@Input() keynoteLimit         : number;
	@Input() fallConclusionLimit  : number;
	public show_detail_page  : boolean = false;
	public myOptions: NgxMasonryOptions = {
		itemSelector: '.conf-grid-item',
		horizontalOrder: true,
		columnWidth: '.grid-sizer',
		percentPosition: true,
	};

	constructor(
		private accountService : AccountServiceProvider,
		public shareddata      : Shareddata,
	) {
		smoothscroll.polyfill();
		if ( 'userData' in window.localStorage ) {
			var userData = JSON.parse( window.localStorage.getItem( 'userData' ) );
			this.shareddata.setUserSession( userData );
		}
	}

	ngOnInit(){}

	ngAfterViewInit() {
		setTimeout (
			() => {
				if ( document.querySelector( '.conf-grid' ) ) {
					var confElem = document.querySelector( '.conf-grid' );
					var confElemmsnry = new Masonry( confElem, {
						itemSelector: '.conf-grid-item',
						columnWidth: '.grid-sizer',
						horizontalOrder: true,
						percentPosition: true,
					});

					imagesLoaded( confElem ).on( 'progress', function() {
						confElemmsnry.layout();
					});
				}
			}, 1
		);
	}

	refreshConference( season, key_limit, fall_conclusion_limit ) {
		/* no keynotes for season conferene and fall */
		document.getElementById( 'conference' ).classList.add( 'loading-conference' );
		let params = {
			'season' : season,
		}
		let wp_params = {
			'limit' : key_limit,
		}
		const wpData         = this.accountService.getKeynote( wp_params );
		const getConferences = this.accountService.getConferences( params );
		if ( 'conference' == season ) {
			Promise.all( [ getConferences ] ).then( response => {
				if ( response[0]['conferences'] != undefined ) {
					const merged = [...response[0]['conferences']]; /* Array/Object spread operator */
					this.shuffleArray(merged);
					this.conferenceDotNetData = merged;
				}
				document.getElementById( 'conference' ).classList.remove( 'loading-conference' );
			});
		} else if( 'fall' == season ) {
			Promise.all( [ getConferences ] ).then( response => {
				if ( response[0]['conferences'] != undefined ) {
					let data_with_conclusion = [];
					let data_without_conclusion = [];
					let first_conclusion_data;
					for ( let value in response[0]['conferences'] ) {
						if ( true == response[0]['conferences'][value].hasConclusion )
							data_with_conclusion.push( response[0]['conferences'][value] );
						else
							data_without_conclusion.push( response[0]['conferences'][value] );
					}
					if ( data_with_conclusion.length > 0 ) {
						if ( fall_conclusion_limit > 1 ) {
							// shuffle data with conclusion
							this.shuffleArray( data_with_conclusion );
							//  get first data with conclusion
							first_conclusion_data = data_with_conclusion[0];
							// remove first data from array
							data_with_conclusion.shift();
							// push remaining data to data without conclusion
							for ( let con_val in data_with_conclusion )
								data_without_conclusion.push( data_with_conclusion[con_val] );
						} else
							first_conclusion_data = data_with_conclusion[0];
					}
					// shuffle remaining data
					this.shuffleArray( data_without_conclusion );
					// add data with conclusion to shuffled  array
					if ( data_with_conclusion.length > 0 ) {
						data_without_conclusion.splice( 0, 0, first_conclusion_data );
					}
					this.conferenceDotNetData = data_without_conclusion;
					document.getElementById( 'conference' ).classList.remove( 'loading-conference' );
				}
			});
		} else {
			if ( 0 == key_limit ) {
				Promise.all( [ getConferences ] ).then( response => {
					this.shuffleArray(response[0]['conferences']);
					this.conferenceDotNetData = response[0]['conferences'];
					document.getElementById( 'conference' ).classList.remove( 'loading-conference' );
				});
			} else {
				Promise.all( [ wpData, getConferences ] ).then( response => {
					if ( response[0]['data'] != undefined && response[1]['conferences'] != undefined ) {
						const merged = [...response[0]['data'], ...response[1]['conferences']]; /* Array/Object spread operator */
						this.shuffleArray(merged);
						this.conferenceDotNetData = merged;
						document.getElementById( 'conference' ).classList.remove( 'loading-conference' );
					} else {
						if ( response[0]['data'] != undefined ) {
							const merged = [...response[0]['data']]; /* Array/Object spread operator */
							this.shuffleArray(merged);
							this.conferenceDotNetData = merged;
							document.getElementById( 'conference' ).classList.remove( 'loading-conference' );
						} else if ( response[1]['conferences'] != undefined ) {
							const merged = [...response[1]['conferences']];
							this.shuffleArray(merged);
							this.conferenceDotNetData = merged;
							document.getElementById( 'conference' ).classList.remove( 'loading-conference' );
						}
					}
				});
			}
		}
	}

	shuffleArray( array ) {
		for (let i = array.length - 1; i > 0; i--) {
	        const j = Math.floor( Math.random() * ( i + 1 ) );
	        [array[i], array[j]] = [array[j], array[i]];
	    }
	}

  	storeColor( color_class ) {
		sessionStorage.setItem( 'backgroundColorClass', JSON.stringify({ 'colorClass': color_class }) );
	}
}
