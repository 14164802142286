// Angular Core
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { InViewportModule } from 'ng-in-viewport';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgxMasonryModule } from 'ngx-masonry';
// Services
import { Webservice } from '../providers/webservice';
import { Dotnetservice } from '../providers/dotnetservice';
import { AccountServiceProvider } from '../providers/account-service/account-service';
import { AppRoutingModule } from './app-routing.module';
import { SharingModule } from './SharedModule';
import { Config } from './config';
// Components
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { NavigationComponent } from './navigation/navigation.component';
import { BannerComponent } from './banner/banner.component';
import { ThreeColumnComponent } from './three-column/three-column.component';
import { InfoComponent } from './info/info.component';
import { ConferenceComponent } from './conference/conference.component';
import { VenueComponent } from './venue/venue.component';
import { PartnerComponent } from './partner/partner.component';
import { NewsComponent } from './news/news.component';
import { SigninsighupmodalComponent } from './signinsighupmodal/signinsighupmodal.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { NewsHomeComponent } from './news-home/news-home.component';
import { HomeWpEngageComponent } from './home-wp-engage/home-wp-engage.component';
import { HomeFactComponent } from './home-fact/home-fact.component';
import { HomeQuoteComponent } from './home-quote/home-quote.component';
import { HomeDoubleContentComponent } from './home-double-content/home-double-content.component';
import { HomeFileComponent } from './home-file/home-file.component';
import { AccordionComponent } from './accordion/accordion.component';
@NgModule({
	declarations: [
		AppComponent,
		FooterComponent,
		NavigationComponent,
		HomeComponent,
		BannerComponent,
		ThreeColumnComponent,
		InfoComponent,
		ConferenceComponent,
		VenueComponent,
		PartnerComponent,
		NewsComponent,
		SigninsighupmodalComponent,
		NewsletterComponent,
		NewsHomeComponent,
		HomeWpEngageComponent,
		HomeFactComponent,
		HomeQuoteComponent,
		HomeDoubleContentComponent,
		HomeFileComponent,
		AccordionComponent,
	],
	entryComponents: [
		AppComponent,
		FooterComponent,
		NavigationComponent,
		SigninsighupmodalComponent,
	],
	imports: [
		AppRoutingModule,
		HttpClientModule,
		HttpClientJsonpModule,
		NgbModule,
		BrowserAnimationsModule,		
		LazyLoadImageModule.forRoot({
			preset: intersectionObserverPreset
		}),
		FormsModule,
		ReactiveFormsModule,
		NgBootstrapFormValidationModule.forRoot(),
		NgBootstrapFormValidationModule,
        InViewportModule,
		NgxSelectModule,
		SharingModule.forRoot(),
		NgxMasonryModule,
	],
	providers: [
		Webservice,
		Dotnetservice,
		AccountServiceProvider,
		NgbActiveModal,
		Config,
	],
	bootstrap: [AppComponent]
})

export class AppModule {}
