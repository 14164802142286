import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
	selector      : 'app-home-quote',
	templateUrl   : './home-quote.component.html',
	encapsulation : ViewEncapsulation.None,
})
export class HomeQuoteComponent implements OnInit {
	@Input() quoteContent : any;	
	constructor() {}
  	ngOnInit(): void {}
}