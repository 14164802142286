<section id="animateNews" class="component component--home--news text-color-light {{ homeNewsContent.display_type }}">
	<div class="wrapper">
		<div class="d-flex justify-content-between listblock_top ">
            <h2 class="listblock_title" *ngIf="'' != homeNewsContent.title" [innerHtml]="homeNewsContent.title"> </h2>
            <a routerLink="{{ homeNewsContent.home_news_link_int }}" class="seeall_link btn-witharrow" *ngIf="'internal' == homeNewsContent.news_link_type"><span class="btn-label">See All</span></a>
            <a href="{{ homeNewsContent.home_news_link }}" class="seeall_link btn-witharrow" target="_blank" *ngIf="'internal' != homeNewsContent.news_link_type"><span class="btn-label">See All</span></a>
		</div>
		<div class="row listing grid-news">
			<div class="grid-sizer col-lg-3 col-sm-6"></div>
            <div class="gridcolumn col-sm-6 col-lg-3 grid-item-news" [ngClass]="'no-big-news' != homeNewsContent.display_type && first ? 'stamp stamp1' : ''" *ngFor="let hVal of homeNewsContent.news_detail;let first = first">
                <div class="related-block-item">
                    <div class="related-block-image">
                        <a href="{{ hVal.link }}" target="_blank" *ngIf="'yes' == hVal.link_type">
                            <figure class="image-center por"><img src="{{ hVal.image }}" class="img-responsive thumbnail" alt="{{ hVal.title }}"></figure>
                        </a>
                        <a routerLink="{{ hVal.link }}" *ngIf="'no' == hVal.link_type">
                            <figure class="image-center por"><img src="{{ hVal.image }}" class="img-responsive thumbnail" alt="{{ hVal.title }}"></figure>
                        </a>
                    </div>
                    <div class="related-block-content">
                        <a href="{{ hVal.link }}" target="_blank" *ngIf="'yes' == hVal.link_type">
                            <span class="related-block-date">
                                <span class="bluebold" *ngIf="'' != hVal.category_name">{{ hVal.category_name }}</span><span class="date-dot">.</span><span class="">{{ hVal.date }}</span>
                            </span>
                            <h4 [innerHtml]="hVal.title"></h4>
                            <p *ngIf="'' != hVal.excerpt && 'no-big-news' != homeNewsContent.display_type && first" [innerHtml]="hVal.excerpt"></p>
                        </a>
                        <a routerLink="{{ hVal.link }}" *ngIf="'no' == hVal.link_type">
                            <span class="related-block-date">
                                <span class="bluebold" *ngIf="'' != hVal.category_name">{{ hVal.category_name }}</span><span class="date-dot">.</span><span class="">{{ hVal.date }}</span>
                            </span>
                            <h4 [innerHtml]="hVal.title"></h4>
                            <p *ngIf="'' != hVal.excerpt && 'no-big-news' != homeNewsContent.display_type && first" [innerHtml]="hVal.excerpt"></p>
                        </a>
                        <div class="keywords-list" *ngIf="'' != hVal.show_tags">	
                            <div class="keywords-list">
                                <span class="pub_keyword" *ngFor="let shownTags of hVal.show_tags"> <span class="tagtext" [innerHtml]="shownTags.name"></span></span>
                                <span class="tagtext more-tagtext" *ngIf="'' != hVal.hide_tags" (click)="showHidden( $event )">...</span>
                                <span class="all-tagtext hide" *ngIf="'' != hVal.hide_tags">
                                    <span class="pub_keyword" *ngFor="let hiddenTags of hVal.hide_tags"><span class="tagtext" [innerHtml]="hiddenTags.name"></span></span>
                                </span>
                            </div>								
                        </div>					
                    </div>
                </div>
            </div>
		</div>
	</div>
</section>