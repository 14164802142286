<section class="component component-fact">
    <div class="wrapper">
        <div class="row">
            <div class="col-sm-8">
                <div class="row fact-block-row">
                    <div class="col-sm-12" *ngIf="'' != factContent.fact_header"><h2 [innerHtml]="factContent.fact_header"></h2></div>
                    <div class="col-sm-12 single-block" *ngIf="'single' == factContent.fact_content_column_type && '' != factContent.fact_content">
                        <div class="editor-content" [innerHtml]="factContent.fact_content"></div>
                    </div>
                    <div class="col-sm-6" *ngIf="'double' == factContent.fact_content_column_type && '' != factContent.left_col_fact_content">
                        <div class="editor-content" [innerHtml]="factContent.left_col_fact_content"></div>
                    </div>
                    <div class="col-sm-6" *ngIf="'double' == factContent.fact_content_column_type && '' != factContent.right_col_fact_content">
                        <div class="editor-content" [innerHtml]="factContent.right_col_fact_content"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>