import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
	selector      : 'app-three-column',
	templateUrl   : './three-column.component.html',
	encapsulation : ViewEncapsulation.None
})

export class ThreeColumnComponent implements OnInit {
	@Input() threeColumnData : any;

	constructor() {}

	ngOnInit() {}

	trackByFn( index, item ) {
	    return item.id;
  	}
}