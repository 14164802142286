import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl  } from '@angular/platform-browser';
import { AccountServiceProvider } from '../../providers/account-service/account-service';
import { Shareddata } from '../../providers/shareddata';
import { FuncsService } from '../../services/funcs.services';

@Component({
	selector      : 'app-home',
	templateUrl   : './home.component.html',
	encapsulation : ViewEncapsulation.None
})

export class HomeComponent implements OnInit {
	public days                  : number;
	public hours                 : number;
	public minutes               : number;
	public seconds               : number;
	public keynote_limit         : number;
	public fall_conclusion_limit : number;
	public i                     : number;
	public result             : any;
	public banner_image       : any = [];
	public conference_season  : any;
	public response           : any;
	public error_message      : any;
	public closedTime         : any;
	public currentTime        : any;
	public banner               : any = [];
	public banner_location_date : any = [];	
	public dateCounter          : any = [];	
	public three_column_block   : any = [];
	public news                 : any = [];
	public info                 : any = [];
	public conference           : any = [];
	public conference_data      : any = [];	
	public venue                : any = [];
	public partners_data        : any = [];
	public partners             : any = [];	
	public newsletter_data      : any = [];	
	public home_news_data       : any = [];
	public wp_engage_data       : any = [];
	public fact_data            : any = [];
	public quote_data           : any = [];
	public double_content_data  : any = [];
	public file_data            : any = [];
	public accordion_data       : any = [];
	public loadButton         : boolean = true;	
	public error_message_show : boolean = false;
	public show_data_counter  : boolean = true;
	public isMobile           : boolean;
	text : any = {
		Year         : 'Year',
		Month        : 'Month',
		Weeks        : "Weeks",
		Days         : "Days",
		Hours        : "Hours",
		Minutes      : "Minutes",
		Seconds      : "Seconds",
		MilliSeconds : "MilliSeconds"
	};
	
	constructor(
		private accountService : AccountServiceProvider,
  		public shareddata      : Shareddata,
  		private funcs          : FuncsService,
		private sanitizer      : DomSanitizer,
	) {
		this.isMobile = this.funcs.globalDetectDevice();
		this.accountService.getStartPageData().then( ( result: any ) => {
			if ( 'Ok' === result.status ) {
				this.response = result.components;
				this.result = this.response;
				this.i      = 0;
				if ( result.seo_data )
					this.funcs.globalSeoData( result );
				for ( let entry of this.response ) {
					if ( 'banner_block' === entry.acf_fc_layout ) {
						this.banner[ this.i ] = entry;
						this.banner_location_date[ this.i ] = entry.banner_location + ' | ' + entry.banner_date;
						if ( this.isMobile )
							this.banner_image[this.i] = this.sanitizer.bypassSecurityTrustResourceUrl( entry.banner_image.sizes.medium );
						else
							this.banner_image[this.i] = this.sanitizer.bypassSecurityTrustResourceUrl( entry.banner_image.url );
						if ( '' !== entry.counter ) {
							this.dateCounter[ this.i ] = entry.counter;
							const countDownDate  = new Date( entry.counter.last_date_of_submission ).getTime();
							var now = new Date().getTime();
							var distance = (countDownDate*1000) - now - 7200000;
							if ( distance > 0 ) {
								const x = setInterval( function() {
									now = new Date().getTime();
									distance = (countDownDate*1000) - now - 7200000;									
									this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
									this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
									this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
									this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
									if ( document.querySelector( '.counter' ) )
										document.querySelector( '.counter' ).classList.remove( 'hide' );
									// Output the result in an element with id="demo"
									if ( document.getElementById( 'counter-days' ) )
										document.getElementById( 'counter-days' ).innerHTML = this.days;
									if ( document.getElementById( 'counter-hours' ) )
										document.getElementById( 'counter-hours' ).innerHTML = this.hours;
									if ( document.getElementById( 'counter-minutes' ) )
										document.getElementById( 'counter-minutes' ).innerHTML = this.minutes;
									if ( document.getElementById( 'counter-seconds' ) )
										document.getElementById( 'counter-seconds' ).innerHTML = this.seconds;
								}, 1000);
							} else
								this.show_data_counter = false;
						} else
							this.show_data_counter = false;
					}
					if ( 'three_column_block' === entry.acf_fc_layout )
						this.three_column_block[ this.i ] = entry;
					if ( 'news_block' === entry.acf_fc_layout )
						this.news[ this.i ] = entry;
					if ( 'info_block' === entry.acf_fc_layout )
						this.info[ this.i ] = entry;
					if ( 'conference_block' === entry.acf_fc_layout ) {
						this.conference[ this.i ] = entry;
						let params = {
							'season' : entry.data.type,
						}
						this.conference_season = params.season;
						this.conference_data = entry.data.data;
						this.keynote_limit = entry.data.keynote_limit;
						this.fall_conclusion_limit = entry.data.conclusion_count;				
					}
					if ( 'venue_block' === entry.acf_fc_layout )
						this.venue[ this.i ] = entry;
					if ( 'partners_block' == entry.acf_fc_layout ) {
						this.partners[ this.i ] = entry;
						// this.accountService.getPartner().then( ( dotnetresponse: any ) => {
						// 	if ( dotnetresponse.status == 'Ok' ) {
						// 		this.partners_data['partner_data'] = dotnetresponse.partnerTypes;
						// 	}
								this.partners_data['partner_data'] = entry.partnerTypes;
						// });
					}
					if ( 'newsletter_block' == entry.acf_fc_layout ) {
						this.newsletter_data[ this.i ] = entry;
					}
					if ( 'home_news' == entry.acf_fc_layout ) {
						this.home_news_data[ this.i ] = entry;
					}
					if ( 'wp_engage_block' == entry.acf_fc_layout ) {
						this.wp_engage_data[ this.i ] = entry;
					}
					if ( 'fact_block' == entry.acf_fc_layout ) {
						this.fact_data[ this.i ] = entry;
						this.fact_data[ this.i ].fact_content = this.sanitizer.bypassSecurityTrustHtml( entry.fact_content );
						this.fact_data[ this.i ].left_col_fact_content = this.sanitizer.bypassSecurityTrustHtml( entry.left_col_fact_content );
						this.fact_data[ this.i ].right_col_fact_content = this.sanitizer.bypassSecurityTrustHtml( entry.right_col_fact_content );
					}
					if ( 'quote_block' == entry.acf_fc_layout ) {
						this.quote_data[ this.i ] = entry;
					}
					if ( 'double_content_column' == entry.acf_fc_layout ) {
						this.double_content_data[ this.i ] = entry;
						this.double_content_data[ this.i ].double_content_repeater.forEach( ( v, i ) => {
							this.double_content_data[ this.i ].double_content_repeater[i].double_content_description = this.sanitizer.bypassSecurityTrustHtml( v.double_content_description );
						});
					}
					if ( 'file_block' == entry.acf_fc_layout ) {
						this.file_data[ this.i ] = entry;
					}
					if ( 'accordion_block' == entry.acf_fc_layout ) {
						this.accordion_data[ this.i ] = entry;
					}
					this.i++;
				}
			} else {
				this.error_message_show = true;
				if ( '3111' == result.errcode ) {
					this.error_message = 'Oops, looks like something went wrong!';
				} else {
					this.error_message      = result.message;
				}
			}
			this.loadButton = false;
		});
	}

	@HostListener('window:popstate', ['$event'])
	onPopState(event) {
		location.reload();
	}

	ngOnInit() {
		document.getElementsByClassName( 'header' )[0].classList.remove( 'inner_page_menu' );
	}

	ngAfterViewInit() {
		if ( 'notificationCloseTime' in window.localStorage ) {
			/* notification bar is hidden */
			this.closedTime = window.localStorage.getItem( 'notificationCloseTime' );
			this.currentTime = new Date().getTime();
			let diff = Math.floor( ( this.currentTime - this.closedTime )  / 1000 / 3600 );
			if ( diff >= 1 ) {
				/* show notification bar if time diff is greater than or equal to 1 hour */
				if ( document.getElementById( 'notification-bar' ).classList.contains( 'hide' ) )
					document.getElementById( 'notification-bar' ).classList.remove( 'hide' );
			} else {
				/* hide notification bar if time diff is less than 1 hour */
				document.getElementById( 'notification-bar' ).classList.add( 'hide' );
			}
		}
	}
}